import { StyledRoot, StyledSearch } from '../styles.css';
import ButtonExport from '@src/components/buttons/button-export';
import { selectedRedeemItems } from '@src/store/slices/redeem.slice';
import { downloadExcel } from '@src/utils/download-helper';
import { useAppSelector } from '@src/store/hooks/hooks';
import Iconify from '../../../components/iconify';
import { Box, IconButton, InputAdornment } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';
import { useRedeemService } from '@src/store/hooks';

export default function RedeemToolbar({ filterName, onFilterName }: any) {
  const redeem = useAppSelector(selectedRedeemItems);
  const { onFetchRedeem } = useRedeemService();

  const onExport = () => {
    const newJson = _.reduce(redeem, (result: any, value: any, key) => {
      result.push({
        id: value.id,
        'User ID': value.userID,
        'Redeem Type': value.redeemTypeID,
        'Redeem Status': value.redeemStatusID,
        'Gold Amount': value.goldAmount,
        'Created Date': moment(value.requestDate).format("MM-DD-YYYY hh:mm A"),
      })

      return result;
    }, []);
    downloadExcel(newJson, 'export-redeem');
  };

  const handleRefresh = () => onFetchRedeem();

  return (
    <StyledRoot sx={{ justifyContent: 'space-between' }}>
      <StyledSearch
        value={filterName}
        onChange={onFilterName}
        placeholder="Search by user id..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
      <Box>
        <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
          <Iconify icon={'mdi:refresh'} width={30} />
        </IconButton>
        <ButtonExport sx={{ marginLeft: 5 }} onClick={onExport} />
      </Box>
    </StyledRoot>
  );
}
