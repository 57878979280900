import React from 'react';
import { ProgramContext } from './provider';
import { Grid, Box, CircularProgress } from '@mui/material';
import numeral from 'numeral';
import Items from './items';
import _ from 'lodash';

const DataSection = () => {
  const { details, loading } = React.useContext(ProgramContext);

  if(loading){
    return (
      <Box sx={{display: 'flex', flex:1, alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress />
      </Box>
    )
  }

  if(_.isEmpty(details)){
    return null; 
  }

  return (
    <Box >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} >
          <Items title="Provider Name" value={details.providerName} />
        </Grid>
        <Grid item xs={6} >
          <Items title="Revenue Share Percentage" value={numeral(details.revenueSharePercentThisGame).format("0,000.00")} />
        </Grid>
        <Grid item xs={6} >
          <Items title={`${details.providerName}: Total Bet`} value={numeral(details.totalBetThisGame).format("0,000")} />
        </Grid>
        <Grid item xs={6} >
          <Items title="All Game: Total Bet" value={numeral(details.totalBetAllGame).format("0,000")} />
        </Grid>
        <Grid item xs={6} >
          <Items title="Gross Revenues" value={numeral(details.totalPurchase).format("0,000.00")} />
        </Grid>
        <Grid item xs={6} >
          <Items title="Commission Due" value={numeral(details.totalCommissionThisGame).format("0,000.00")} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default DataSection;
