import Request from './Request';
import Constant from '../config';

// API CORE
export const CurrentApi = new Request(Constant.API_BASE_URL);

export const setToken = (token: string) => {
  CurrentApi.setToken(token);
};

export const callGet = async (routes: string, token?: string) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }

    const result = await CurrentApi.get(`${routes}`);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callPost = async (
  routes: string,
  params: any,
  token?: string | undefined,
  headers?: any,
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }

    const result = await CurrentApi.post(`${routes}`, { ...params }, headers);

    return result;
  } catch (e) {

    console.log("callPost",e)
    throw e;
  }
};

export const callPut = async (
  routes: string,
  params: any,
  token?: string | undefined
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }

    const result = await CurrentApi.put(`${routes}`, { ...params });

    return result;
  } catch (e) {
    throw e;
  }
};
