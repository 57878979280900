import { memo } from 'react';
import RowLoading from '@src/components/table-row';
import { TableBody } from '@mui/material';
import TableRowItem from './data-item'
import SearchNotFound from '@src/components/notfound';

type DataTableRowProps = {
  data: any[];
  headers: any[];
  colSpan?: number;
  isLoading?: boolean;
  isRefreshing?: boolean;
  renderItem?: any;
  onRowPress?: any;
  disabled?: boolean;
  isNotFound?: boolean;
  filterName?: string;
};

const DataTableRow = (props: DataTableRowProps) => {
  const { headers, renderItem, data, colSpan, isLoading, isRefreshing, isNotFound, filterName } = props;

  if (isRefreshing) return <RowLoading colSpan={colSpan} isLoading />;

  return (
    <>
      <TableBody>
        {data.map((item: any, index: number) => {
          if (typeof renderItem === 'function') {
            return renderItem({ item, index });
          }

          return <TableRowItem key={`${index}`} {...{ item, headers }} />
        })}
      </TableBody>
      <RowLoading colSpan={colSpan} isLoading={isLoading} />
      <SearchNotFound colSpan={colSpan} isNotFound={isNotFound} filterName={filterName} />
    </>
  );
};

export default memo(DataTableRow);
