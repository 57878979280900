/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext } from 'react';
import { useInputHelper } from '@src/utils/inputs-helper';
import { useFetchPost } from '@src/utils/api/hooks';

const initialState = {
  from_date: null,
  to_date: null,
  providerName: "Evo",
}
interface ContextValue {
  state: any;
  loading: boolean;
  details: any;
  onDispatch: any;
  onGenerate: () => void;
}

export const ProgramContext = createContext<ContextValue>({} as ContextValue);
interface ProviderProps {
  children: React.ReactElement;
}

const ProgramProvider = ({ children }: ProviderProps) => {
  const { state, onDispatch } = useInputHelper(initialState);
  const { loading, data, runRequest } = useFetchPost();

  console.log("state", state, data)

  const onGenerate = React.useCallback(() => {
    const params = {
      providerName: state.providerName,
      from_date: state.from_date ? state.from_date.format("YYYY-MM-DD") : null,
      to_date: state.to_date ? state.to_date.format("YYYY-MM-DD") : null,
    }

    runRequest("/api/v1/game/commissionForGameProvider", params);
  }, [state]);

  const value = {
    state, onDispatch,
    loading,
    onGenerate,
    details: data?.data ?? {}
  };

  React.useEffect(() => {
    onGenerate();
  }, []);

  return (
    <ProgramContext.Provider value={value}>
      {children}
    </ProgramContext.Provider>
  );
};

export default ProgramProvider;
