import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';

// Types
import * as Types from '../types';
import {selectedBalanceUpdate, selectedUserItems, selectedUsersData, selectedUsersFailed, selectedUsersLoading, selectedUsersRefreshing, usersActions} from '../slices/users.slice';

type ServiceOperators = {
  data: Types.UsersValue;
  users: any;
  isLoading: boolean;
  isRefreshing: boolean;
  failed: Types.Error;
  balanceData: any;
  onFetchUsers: () => void;
  onRefreshUsers: () => void;
  onFetchUsersMore: (params: any) => void;
  onUpdateBalance: (params: any) => void;
  onUpdateUserState: (params: any) => void;
  onSearchUser: (params: any) => void;
  onUpdateUserDetails: (params: any) => void;
};

export const useUserService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    data: useAppSelector(selectedUsersData),
    users: useAppSelector(selectedUserItems),
    failed: useAppSelector(selectedUsersFailed),
    isLoading: useAppSelector(selectedUsersLoading),
    isRefreshing: useAppSelector(selectedUsersRefreshing),
    balanceData: useAppSelector(selectedBalanceUpdate),
    onFetchUsers: useCallback(() => {
      dispatch(usersActions.usersRequest({ 
        "email": "",
        "deleted": 0,
        "page": 1,
        "size": 50,
        "sort":"enrollmentDate,desc" 
      }));
    },[dispatch]),
    onFetchUsersMore: useCallback((params: any) => {
      dispatch(usersActions.usersRequest(params));
    },[dispatch]),
    onRefreshUsers: useCallback(() => {
      dispatch(usersActions.usersRefreshRequest({ 
        "email": "",
        "deleted": 0,
        "page": 1,
        "size": 50,
        "sort":"enrollmentDate,desc" 
      }));
    },[dispatch]),
    onUpdateBalance: useCallback((params: any) => {
      dispatch(usersActions.updateBalanceRequest(params));
    },[dispatch]),
    onUpdateUserState: useCallback((params: any) => {
      dispatch(usersActions.updateUserState(params));
    },[dispatch]),
    onSearchUser: useCallback((params: any) => {
      dispatch(usersActions.searchUserRequest(params));
    },[dispatch]),
    onUpdateUserDetails: useCallback((params: any) => {
      dispatch(usersActions.searchUserSuccess(params));
    },[dispatch]),
  };
};

export default useUserService;
