// DUCKS pattern
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import * as Types from '../types';
import _ from 'lodash';

export interface StateValue {
  loading: boolean;
  data: Types.UsersValue;
  error: Types.Error;
}

const initialState: StateValue = {
  loading: false,
  data: {} as Types.UsersValue,
  error: {} as Types.Error,
} as StateValue;

// Slice
export const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    purchaseRequest: (state, action) => {
      state.loading = true;
      if(action.payload.filter){
        state.data = {} as Types.UsersValue;
      }
    },
    purchaseSuccess: (state, action) => {
      let items = _.unionBy(state.data?.items ?? [], action.payload.items, "id");
      state.data = { ...action.payload, items};
      state.loading = false;
    },
    purchaseFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

// Actions
export const purchaseActions = {
  purchaseRequest: createAction(
    `${purchaseSlice.name}/purchaseRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  purchaseSuccess: purchaseSlice.actions.purchaseSuccess,
  purchaseFailure: purchaseSlice.actions.purchaseFailure,
};

// Selectors
export const selectedPurchaseLoading = (state: RootState) => state.purchase.loading;
export const selectedPurchaseData = (state: RootState) => state.purchase.data;
export const selectedPurchaseFailed = (state: RootState) => state.purchase.error;
export const selectedPurchaseItems = createSelector(
  (state: RootState) => state.purchase.data,
  state => state.items || []
);
export const selectedPurchaseTotal = createSelector(
  (state: RootState) => state.purchase.data,
  state => state.totalRecords || 0
);

// Reducer
export default purchaseSlice.reducer;
