import {
  TableRow,
  TableCell,
} from '@mui/material';
import moment from 'moment';
import numeral from 'numeral';


const TableRowItem = (props: any) => {

  return (
    <TableRow hover tabIndex={-1} role="checkbox">
      <TableCell align="left">{props.gameId}</TableCell>
      <TableCell align="left">{props.roundID}</TableCell>
      <TableCell align="left">{props.callbackActionType}</TableCell>
      <TableCell align="left">{props.currency}</TableCell>
      <TableCell align="left">{numeral(props.totalBet).format("0,000.00")}</TableCell>
      <TableCell align="left">{numeral(props.totalWin).format("0,000.00")}</TableCell>
      <TableCell align="left">{moment(props.createdDate).format("MM-DD-YYYY hh:mm A")}</TableCell>
    </TableRow>
  );
}

export default TableRowItem;
