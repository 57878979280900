import React from 'react';
import { MenuItem, Popover } from '@mui/material'
import { GamesContext } from './provider';

function ActionMenu(props: any) {
  const { onDispatch } = React.useContext(GamesContext);

  const onViewMoreDetails = () => {
    props.handleCloseMenu();
    onDispatch('gamedetails')(props.selectedGame);
  }

  return (
    <Popover
      open={Boolean(props.open)}
      anchorEl={props.open}
      onClose={() => props.handleCloseMenu()}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 160,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        },
      }}
    >
      <MenuItem onClick={onViewMoreDetails}>
        Edit
      </MenuItem>
    </Popover>
  )
}

export default ActionMenu;