// DUCKS pattern
import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import * as Types from '../types';
import _ from 'lodash';

export interface StateValue {
  loading: boolean;
  exporting: boolean;
  data: Types.UsersValue;
  error: Types.Error;
  exportError?: Types.Error;
}

const initialState: StateValue = {
  loading: false,
  exporting: false,
  data: {} as Types.UsersValue,
  error: {} as Types.Error,
  exportError: {} as Types.Error,
} as StateValue;

// Slice
export const coinhistorySlice = createSlice({
  name: 'coinhistory',
  initialState,
  reducers: {
    coinhistoryRequest: (state, action) => {
      state.loading = true;
      if(action.payload.refresh){
        state.data = {} as Types.UsersValue;
      }
    },
    coinhistorySuccess: (state, action) => {
      let items = action.payload.items;
      if(action.payload.isFetchMore){
        items = _.union(state.data?.items ?? [], action.payload.items);
      }

      state.data = { ...action.payload, items};
      state.loading = false;
    },
    coinhistoryFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    coinhistoryExportRequest: (state, action) => {
      state.exporting = true;
    },
    coinhistoryExportSuccess: (state, action) => {
      state.exporting = false;
    },
    coinhistoryExportFailure: (state, action) => {
      state.exportError = action.payload;
      state.exporting = false;
    },
  },
});

// Actions
export const coinhistoryActions = {
  coinhistoryRequest: createAction(
    `${coinhistorySlice.name}/coinhistoryRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  coinhistorySuccess: coinhistorySlice.actions.coinhistorySuccess,
  coinhistoryFailure: coinhistorySlice.actions.coinhistoryFailure,
  coinhistoryExportRequest: createAction(
    `${coinhistorySlice.name}/coinhistoryExportRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  coinhistoryExportSuccess: coinhistorySlice.actions.coinhistoryExportSuccess,
  coinhistoryExportFailure: coinhistorySlice.actions.coinhistoryExportFailure,
};

// Selectors
export const selectedCoinHistoryLoading = (state: RootState) => state.coinhistory.loading;
export const selectedCoinHistoryExportLoading = (state: RootState) => state.coinhistory.exporting;
export const selectedCoinHistoryData = (state: RootState) => state.coinhistory.data;
export const selectedCoinHistoryFailed = (state: RootState) => state.coinhistory.error;
export const selectedCoinHistoryItems = createSelector(
  (state: RootState) => state.coinhistory.data,
  state => state.items || []
);
export const selectedCoinHistoryTotal = createSelector(
  (state: RootState) => state.coinhistory.data,
  state => state.totalRecords || 0
);

// Reducer
export default coinhistorySlice.reducer;
