/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Table,
  Stack,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';

// Helper
import { getComparator, applySortFilter } from '../../utils/filter-helper';
import { usePurchaseService } from '@src/store/hooks';
import ScrollBar from '@src/components/scrollbar';

// sections
import { PurchaseListToolbar } from '../../sections/@dashboard/purchase';
import { TABLE_HEAD_PURCHASE } from '@src/utils/mockdata/table-headers';
import DataTableRow from '@src/components/data-table/data-row';
import TableHeader from '@src/components/data-table/header';
import { useLocation } from 'react-router-dom';
import { PurchaseContext } from './provider';
import moment from 'moment';
import TableRowItem from './table-row';


const MainScreen = () => {
  const location = useLocation();
  const emailAddress = location?.state?.email ?? "";
  const { state, onDispatch } = useContext(PurchaseContext);
  const { data, purchases, isLoading, onFetchPurchaseMore } = usePurchaseService();

  const handleRequestSort = (property: any) => {
    const isAsc = state.orderBy === property && state.order === 'asc';
    onDispatch('order')(isAsc ? 'desc' : 'asc');
    onDispatch('orderBy')(property);
  };

  const handleChangeRowsPerPage = (event: any) => {
    onDispatch('page')(0);
    onDispatch('rowsPerPage')(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: any) => {
    onDispatch('page')(0);
    onDispatch('filterName')(event.target.value);
  };

  const handleChangePage = (event: any, newPage: any) => {
    onDispatch('page')(newPage);
    if((newPage + 1) * state.rowsPerPage >  purchases.length ){
      onFetchPurchaseMore({
        from_date: state.fromdate ? moment(state.fromdate).format("YYYY-MM-DD") : null,
        to_date: state.todate ? moment(state.todate).format("YYYY-MM-DD") : null,
        user_id: state.userid,
        "status": state.status,
        "payment_type_id":"",
        "page": newPage + 1,
        "size": state.rowsPerPage,
        "sort":"transactionDate,desc"
      });
    }
  };

  const filteredUsers = React.useMemo(() => 
    applySortFilter(purchases, getComparator(state.order, state.orderBy), state.filterName, "emailAddress"), 
    [purchases, state.filterName, state.order, state.orderBy]);
  const isNotFound = !filteredUsers.length && !!state.filterName;

  return (
    <>
      <Helmet>
        <title> Purchase </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {emailAddress ? `Purchase record by ${emailAddress}` : "All Purchases"}
          </Typography>
        </Stack>
        <Card >
          <PurchaseListToolbar filterName={state.filterName} onFilterName={handleFilterByName} />
          <ScrollBar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader
                  order={state.order}
                  orderBy={state.orderBy}
                  headLabel={TABLE_HEAD_PURCHASE}
                  onRequestSort={handleRequestSort}
                />
                <DataTableRow
                  colSpan={9}
                  isLoading={isLoading}
                  isNotFound={isNotFound}
                  headers={TABLE_HEAD_PURCHASE}
                  filterName={state.filterName}
                  data={filteredUsers.slice(state.page * state.rowsPerPage, 
                    state.page * state.rowsPerPage + state.rowsPerPage)}
                  renderItem={({item, index}: any) => {
                    return <TableRowItem key={`${index}`} {...item} />
                  }} />
              </Table>
            </TableContainer>
          </ScrollBar>
          <TablePagination
            page={state.page}
            component="div"
            rowsPerPageOptions={[10, 20, 100]}
            count={data?.totalRecords ?? 0}
            rowsPerPage={state.rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
export default React.memo(MainScreen);
