import React from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { LocalizationProvider } from '@mui/x-date-pickers-pro'; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TransactionContext } from '@src/pages/transaction/provider';
import { useTransactionService } from '@src/store/hooks';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import _ from 'lodash';

const TransactionFilterSidebar = ({ openFilter, onOpenFilter, onCloseFilter }: any) => {
  const [error, setError] = React.useState<any>({});
  const { isLoading, onFetchTransactionsMore } = useTransactionService();
  const { state, onDispatch } = React.useContext(TransactionContext);

  const applyFilter = () => {
    const newError: any = {};
    if(!state.fromdate){
      newError.fromdate = "This field is required.";
    }
    if(!state.todate){
      newError.todate = "This field is required.";
    }

    setError(newError);

    if(_.isEmpty(newError)){
      onDispatch('page')(0);
      onFetchTransactionsMore({
        fromDate:  moment(state.fromdate).format("YYYY-MM-DD"),
        toDate:  moment(state.todate).format("YYYY-MM-DD"),
        filter: true,
        "page": 1,
        "size": 50, 
        "sort": "CreatedDate,desc"
      });
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Transaction date
              </Typography>
              <MobileDatePicker
                label="From"
                value={state.fromdate}
                sx={{marginTop: 2}}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: 'outlined',
                    error: !_.isEmpty(error.fromdate),
                    helperText: error?.fromdate,
                  },
                }}
                onChange={onDispatch('fromdate')}
              />
              <MobileDatePicker
                label="To"
                sx={{marginTop: 3}}
                value={state.todate}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: 'outlined',
                    error: !_.isEmpty(error.todate),
                    helperText: error?.todate,
                  },
                }}
                onChange={onDispatch('todate')}
              />
            </div>
          </Stack>
        </Scrollbar>
        <Box sx={{ p: 3 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            loading={isLoading}
            onClick={applyFilter}
            startIcon={<Iconify icon="ic:check" />}
          >
            Apply
          </LoadingButton>
        </Box>
      </Drawer>
    </LocalizationProvider>
  );
}

export default TransactionFilterSidebar;
