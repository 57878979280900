import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  selectedAuthToken,
} from '../slices/auth.slice';


// Types
import * as Types from '../types';
import {selectedCoinHistoryItems, 
  selectedCoinHistoryData, 
  selectedCoinHistoryFailed, 
  selectedCoinHistoryLoading, 
  coinhistoryActions} from '../slices/coinhistory.slice';

type ServiceOperators = {
  data: Types.UsersValue;
  coinhistory: any;
  isLoading: boolean;
  failed: Types.Error;
  onFetchCoinHistory: (user_id: number) => void;
  onFetchCoinHistoryMore: (params: any) => void;
  onExportCoinHistory: (params: any) => void;
};

export const useCoinHistoryService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectedAuthToken);

  return {
    data: useAppSelector(selectedCoinHistoryData),
    coinhistory: useAppSelector(selectedCoinHistoryItems),
    failed: useAppSelector(selectedCoinHistoryFailed),
    isLoading: useAppSelector(selectedCoinHistoryLoading),
    onFetchCoinHistory: useCallback(
      (user_id: number) => {
        dispatch(coinhistoryActions.coinhistoryRequest({ 
          token,
          user_id,
          refresh: true,
          "page": 1, 
          "size": 50, 
          "sort": "enrollmentDate,desc"
        }));
      },
      [dispatch, token]
    ),
    onFetchCoinHistoryMore: useCallback(
      (params: any) => {
        dispatch(coinhistoryActions.coinhistoryRequest({
          token,
          isFetchMore: true,
          ...params
        }));
      },
      [dispatch, token]
    ),
    onExportCoinHistory: useCallback(
      (params: any) => {
        dispatch(coinhistoryActions.coinhistoryExportRequest({ 
          token,
          ...params
        }));
      },
      [dispatch, token]
    ),
  };
};

export default useCoinHistoryService;
