import React, { createContext } from 'react';
import { useInputHelper } from '@src/utils/inputs-helper';

const initialState = {
  page: 0,
  order: 'asc',
  orderBy: 'name',
  filterName: '',
  rowsPerPage: 10,
}
interface ContextValue {
  state: any;
  onDispatch: any;
}

export const TransactionContext = createContext<ContextValue>({} as ContextValue);

interface ProviderProps {
  children: React.ReactElement;
}

const TransactionProvider = ({ children }: ProviderProps) => {
  const { state, onDispatch } = useInputHelper(initialState);

  const value = {
    state, onDispatch,
  };

  return (
    <TransactionContext.Provider value={value}>
      {children}
    </TransactionContext.Provider>
  );
};

export default TransactionProvider;
