import { all, fork } from 'redux-saga/effects';

import authWatcherSaga from './auth.sagas';
import serviceWatcherSaga from './services.sagas';

function* rootSaga() {
  yield all([fork(authWatcherSaga), fork(serviceWatcherSaga)]);
}

export default rootSaga;
