import * as React from 'react';
import { Box, Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import { getAccountTypeValue, getAmountBalanceByCoinType, getCountryGender, getCountryValue, getGenderValue, getStatusValue } from '@src/utils/filter-helper';
import { ACCOUNT_TYPE, COUNTRIES, GENDER, STATUS } from '@src/utils/mockdata/menu-options';
import { useInputHelper } from '@src/utils/inputs-helper';
import { useFetchPost } from '@src/utils/api/hooks';
import { styled } from '@mui/material/styles';
import { UserContext } from '../provider';
import TextView from './text-field';
import Dropdown from './dropdown';
import Header from './header';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
import { useUserService } from '@src/store/hooks';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function UserDetailsDialog() {
  const { onUpdateUserDetails } = useUserService();
  const { loading, data, runRequest } = useFetchPost();
  const [editable, setEditable] = React.useState(false);
  const { state, onDispatch } = React.useContext(UserContext);
  const { state: input, onDispatch: onDispatchState, onSetInitial } = useInputHelper();

  const onChangeValue = (type: string) => (e: any) => {
    onDispatchState(type)(e);
  }

  const onChangeText = (type: string) => (e: any) => {
    console.log(type, e.target.value)
    onDispatchState(type)(e.target.value);
  }

  const handleClose = () => {
    onDispatch("userdetails")({});
  };

  const onHandleSave = () => {
    const params = {
      "acctType": input.acctType?.value,
      "genderId": input.genderId?.value,
      "countryId": input.countryId?.value,
      "partnerId": input.partnerId,
      "address": input.address,
      "displayName": input.displayName,
      "firstName": input.firstName,
      "lastName": input.lastName,
      "phoneNumber": input.phoneNumber,
      "status": input.status.value,
      "userId": input.userId
    };

    runRequest("/api/v1/user/updateUserDetails", params);
  };

  const userdetails = React.useMemo(() => {
    const result = getAmountBalanceByCoinType(state.userdetails.userBalanceList || []);
    const userDetail = getCountryGender(state.userdetails.userDetail);
    const userJade = state.userdetails.userJade || {};
    const status = state.userdetails.isDeleted ? "Deactivated" : "Active";

    return {
      ...state.userdetails, 
      ...userDetail,
      ...result, 
      status, jadeAmout: userJade.amount
    }
  },[state.userdetails]);

  React.useEffect(() => {
    if(!_.isEmpty(userdetails)){
      onSetInitial({
        "acctType": getAccountTypeValue(userdetails.userTypeID),
        "address": userdetails.street || "",
        "birthday": userdetails.dateOfBirth ? moment(userdetails.dateOfBirth).format("MM/DD/YYYY") : moment().format(),
        "countryId": getCountryValue(userdetails.countryId),
        "displayName": userdetails.displayName || "",
        "firstName": userdetails.firstName || "",
        "genderId": getGenderValue(userdetails.gender),
        "lastName": userdetails.lastName || "",
        "partnerId": userdetails.partnerId || 1,
        "phoneNumber": userdetails.phoneNumber || "",
        "status": getStatusValue(userdetails.status),
        "userId": userdetails.id || ""
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userdetails]);

  React.useEffect(() => {
    if(!_.isEmpty(data?.data)){
      onUpdateUserDetails({items: [data.data]});
      onDispatch("userdetails")({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  return (
    <div>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        open={!_.isEmpty(state.userdetails)}
        aria-labelledby="customized-dialog-title"
      >
        <Header id="customized-dialog-title"
          editable={editable}
          loading={loading}
          onSave={onHandleSave}
          onEdit={() => setEditable(!editable)} >
          User details
        </Header>
        <DialogContent dividers sx={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start'}}>
          <Box>
            <TextView label="User ID" value={userdetails.id} disabled />
            <TextView label="First Name" value={input.firstName} 
              disabled={!editable} onChange={onChangeText("firstName")} />
            <TextView label="Last Name" value={input.lastName} 
              disabled={!editable} onChange={onChangeText("lastName")} />
            <TextView label="Email Address" value={userdetails.emailAddress} disabled />
            <TextView label="Date Of Birth" value={userdetails.dateOfBirth ? moment(userdetails.dateOfBirth).format("MM/DD/YYYY") : "N/A"} disabled />
            {editable ? <Dropdown options={GENDER} label="Gender" value={input.genderId} onChangeValue={onChangeValue("genderId")} /> : 
              <TextView label="Gender" value={input.genderId?.label} disabled/>}
            {editable ? <Dropdown options={COUNTRIES} label="Country" value={input.countryId} onChangeValue={onChangeValue("countryId")} /> :
              <TextView label="Country" value={input.countryId?.label} disabled/>}
            <TextView label="Address" value={input.address} 
              disabled={!editable} onChange={onChangeText("address")} />
            <TextView label="Phone Number" value={input.phoneNumber} 
              disabled={!editable} onChange={onChangeText("phoneNumber")} />
            <Box sx={{height: 15}} />
          </Box>
          <Box>
            <TextView label="Display Name" value={input.displayName} 
              disabled={!editable} onChange={onChangeText("displayName")}/>
            {editable ? <Dropdown options={ACCOUNT_TYPE} label="Account Type" value={input.acctType} onChangeValue={onChangeValue("acctType")} /> :
              <TextView label="Account Type" value={input.acctType?.label} disabled/>}
            {editable ? <Dropdown options={STATUS} label="Status" value={input.status} onChangeValue={onChangeValue("status")} /> :
              <TextView label="Status" value={input.status?.label} disabled/>}
            <TextView label="Silver balance" value={numeral(userdetails.SILVER).format("0,000")} disabled/>
            <TextView label="GWz Gold" value={numeral(userdetails.GOLD).format("0,000.00")} disabled/>
            <TextView label="Gold balance" value={numeral(userdetails["GOLD BONUS"]).format("0,000.00")} disabled/>
            <TextView label="Jade Amount" value={numeral(userdetails.jadeAmout).format("0,000")} disabled/>
            <TextView label="Date Created" value={moment(userdetails.enrollmentDate).format("MM-DD-YYYY hh:mm A")} disabled/>
            <TextView label="Last Modified" value={moment(userdetails.lastModifiedDate).format("MM-DD-YYYY hh:mm A")} disabled/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>Close</Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default React.memo(UserDetailsDialog);