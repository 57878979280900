import * as React from 'react';
import { RedeemContext } from '../provider';
import { Box, TextField, Typography } from '@mui/material';

const boxStyle = { display: 'flex', flex: 1, flexDirection: 'row', 
   justifyContent: 'space-around', marginTop: 2 };

const PaypalDetails = () => {
  const { state } = React.useContext(RedeemContext);

  if(state?.selected?.redeemTypeID !== 2){
    return null;
  }

  return (
    <>
      <Typography sx={{ m: 0, p: 2, fontSize: 14, fontWeight: "600" }}>
        Paypal Details
      </Typography>
      <Box sx={boxStyle}>
        <TextField
          disabled
          label={"First Name"}
          defaultValue={state?.selected?.paypal_FirstName}
          sx={{ minWidth: 250 }} 
          />
        <TextField
          disabled
          label={"Last Name"}
          defaultValue={state?.selected?.paypal_LastName}
          sx={{ minWidth: 250 }} />
      </Box>
      <Box sx={boxStyle}>
        <TextField
          disabled
          label={"Paypal Username"}
          defaultValue={state?.selected?.bank_Name}
          sx={{ minWidth: 250 }} 
          />
        <TextField
          disabled
          label={"Paypal Email"}
          defaultValue={state?.selected?.paypal_Email ?? ""}
          sx={{ minWidth: 250 }} />
      </Box>
    </>
  );
}

export default PaypalDetails;