/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useAuthService } from '../../../store/hooks';
import Iconify from '../../../components/iconify';
import _ from 'lodash';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [error, setError] = useState<any>({});
  const { isLoading, onSignin, failed } = useAuthService();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    const newError: any = {};

    if(!username){
      newError.username = true;
    }

    if(!password){
      newError.password = true;
    }

    setError(newError);

    if(_.isEmpty(newError)){
      // navigate('/dashboard', { replace: true });
      onSignin({username, password});
    }
  };

  useEffect(() => {
    if(!_.isEmpty(error)){
      setError({});
    }
  },[username, password]);

  useEffect(() => {
    if(!_.isEmpty(failed)){
      setError({message: failed.message});
    }
  },[failed]);

  return (
    <>
      <Stack spacing={3}>
      {error.message && <Typography variant="h5" textAlign={'center'} color='red' component="h2">
        {error.message}
      </Typography>}
        <TextField
          required
          name="username"
          label="Username" 
          value={username}
          error={error.username}
          onChange={(e) => setUsername(e.target.value)} />
        <TextField
          required
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          error={error.password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>
      <LoadingButton loading={isLoading} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
