import SvgColor from '@src/components/svg-color';

const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const FullAccess = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: icon('ic_user'),
  },
  {
    title: 'games',
    path: '/dashboard/games',
    icon: icon('ic_games'),
  },
  {
    title: 'purchase',
    path: '/dashboard/purchase',
    icon: icon('ic_cart'),
  },
  {
    title: 'most played',
    path: '/dashboard/mostplayed',
    icon: icon('ic_star'),
  },
  {
    title: 'redeem transactions',
    path: '/dashboard/redeem',
    icon: icon('ic_star'),
  },
];

const LionsPartner = [
  {
    title: 'purchase',
    path: '/dashboard/purchase',
    icon: icon('ic_cart'),
  },
  {
    title: 'most played',
    path: '/dashboard/mostplayed',
    icon: icon('ic_star'),
  },
];

const getDrawerList = (userTypeID: number) => {
  if(userTypeID !== 4){
    return FullAccess;
  }else{
    return LionsPartner;
  }
}

export default getDrawerList;
