/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from '@mui/material';
// components
import Scrollbar from '@src/components/scrollbar';
// mock
import { getComparator, applySortFilter } from '../../utils/filter-helper';
import { TABLE_HEAD_COIN_HISTORY } from '@src/utils/mockdata/table-headers';
import DataTableRow from '@src/components/data-table/data-row';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import TableHeader from '@src/components/data-table/header';
import { useCoinHistoryService } from '@src/store/hooks';
import Iconify from '@src/components/iconify/Iconify';
import { CoinHistoryContext } from './provider';
import TableRowItem from './table-row';
import ButtonExport from '@src/components/buttons/button-export';
import { StyledWrapRight } from '@src/sections/@dashboard/styles.css';

const Body = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const location = useLocation();
  const user_id = location?.state?.user_id ?? "";
  const displayName = location?.state?.displayName ?? "";
  const { state, onDispatch } = useContext(CoinHistoryContext);
  const { data, coinhistory, isLoading, onFetchCoinHistory, onFetchCoinHistoryMore, onExportCoinHistory } = useCoinHistoryService();

  const userId = useMemo(() => user_id || params.get('u'), []);

  const handleRequestSort = (property: any) => {
    const isAsc = state.orderBy === property && state.order === 'asc';
    onDispatch('order')(isAsc ? 'desc' : 'asc');
    onDispatch('orderBy')(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    onDispatch('page')(newPage);

    if ((newPage + 1) * state.rowsPerPage > coinhistory.length) {
      onFetchCoinHistoryMore({
        user_id: userId,
        "page": newPage + 1,
        "size": state.rowsPerPage,
        "sort": "enrollmentDate,desc"
      });
    }
  };

  const handleChangeRowsPerPage = (event: any) => {
    onDispatch('page')(0);
    onDispatch('rowsPerPage')(parseInt(event.target.value, 10));
  };

  const handleExport = () => {
    onExportCoinHistory({
      user_id: userId,
      "sort": "enrollmentDate,desc"
    });
  }

  const handleRefresh = () => onFetchCoinHistory(userId);

  const filteredCoins = useMemo(() =>
    applySortFilter(coinhistory, getComparator(state.order, state.orderBy), "", "id", 'emailAddress'),
    [coinhistory, state.order, state.orderBy]);

  useEffect(() => {
    onFetchCoinHistory(userId);
  }, []);

  return (
    <Container>
      <Stack direction="row" alignItems="center" mb={5}>
        <IconButton size="large" color="default" onClick={() => navigate(-1)}>
          <Iconify icon={'mdi:arrow-left'} width={30} />
        </IconButton>
        <Typography variant="h4">
          {`User (${displayName}) coin history`}
        </Typography>
      </Stack>

      <StyledWrapRight>
        <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
          <Iconify icon={'mdi:refresh'} width={30} />
        </IconButton>
        <ButtonExport disabled={isLoading || !filteredCoins.length} onClick={handleExport} />
      </StyledWrapRight>

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHeader
                order={state.order}
                orderBy={state.orderBy}
                headLabel={TABLE_HEAD_COIN_HISTORY}
                onRequestSort={handleRequestSort}
              />
              <DataTableRow
                colSpan={7}
                isLoading={isLoading}
                headers={TABLE_HEAD_COIN_HISTORY}
                data={filteredCoins.slice(state.page * state.rowsPerPage,
                  state.page * state.rowsPerPage + state.rowsPerPage)}
                renderItem={({ item, index }: any) => {
                  return <TableRowItem key={`${index}`} {...item} />
                }} />
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          page={state.page}
          component="div"
          rowsPerPage={state.rowsPerPage}
          count={data?.totalRecords ?? 0}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 20, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}

export default React.memo(Body);
