import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { RedeemContext } from '../provider';
import { Box, TextField } from '@mui/material';
import CoinType from './redeem-status';
import { LoadingButton } from '@mui/lab';
import { useRedeemService } from '@src/store/hooks';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedAuthToken } from '@src/store/slices/auth.slice';
import { redeemTypeName } from '@src/utils/filter-helper';
import { SORT_BY_OPTIONS } from './redeem-status';
import { selectedRedeemLoading, selectedRedeemUpdate } from '@src/store/slices/redeem.slice';
import { getAllUsers, getTierID } from '@src/utils/api';
import BankDetails from './bank-details';
import PaypalDetails from './paypal-details';
import ProofOfIDs from './proofofids';
import UserDetailsDialog from './user-details';
import PromotionItem from "./promotion-item";
import GiftCardItem from "./giftcard-item";
import _ from 'lodash';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const boxStyle = { display: 'flex', flex: 1, flexDirection: 'row', 
   justifyContent: 'space-around', marginTop: 2 };

export default function UpdateRedeemStatusDialog(props: any) {
  const data = useAppSelector(selectedRedeemUpdate);
  const [user, setUser] = React.useState<any>({});
  const token = useAppSelector(selectedAuthToken);
  const { onUpdateRedeemStatus } = useRedeemService();
  const loading = useAppSelector(selectedRedeemLoading);
  const [loading2, setLoading] = React.useState(false);
  const { state, onDispatch } = React.useContext(RedeemContext);
  const [redeemStatus, setRedeemStatus] = React.useState<any>({});
  
  const handleClose = () => {
    onDispatch("selected")({})
  };

  const handleUpdate = () => {
    const params = {
      ...state.selected,
      goldAmount: state.selected.goldAmount,
      bankFirstName: state.selected.bank_FirstName,
      bankLastName: state.selected.bank_LastName,
      bankName: state.selected.bank_Name,
      bankSwiftCode: state.selected.bank_SwiftCode,
      bankAccountNumber: state.selected.bank_AccountNumber,
      bankIbanIbcCode: state.selected.bank_IBAN_IBC_Code,
      "paypalEmail": state.selected.paypal_Email,
      "paypalFirstName": state.selected.paypal_FirstName,
      "paypalLastName": state.selected.paypal_UserName,
      redeemStatus: {
        id: redeemStatus.value,
        name: redeemStatus.label,
      },
      redeemType: {
        id: state?.selected?.redeemTypeID,
        name: redeemTypeName(state?.selected?.redeemTypeID)
      },
    }

    onUpdateRedeemStatus(params);
  }

  const onSelectUserID = async() => {
    try{
      setLoading(true);
      const params = { 
        userID: state?.selected?.userID,
        "page": 1,
        "size": 50,
        "sort":"enrollmentDate,desc"
      }

      const result = await getAllUsers(params, token);
      const tier = await getTierID({userId: state?.selected?.userID, token});
      setLoading(false);
      setUser({...result.data.items[0], tierId: tier.tierID});
    }catch(e){
      setLoading(false);
    }
  }

  React.useEffect(() => {
    const result = SORT_BY_OPTIONS.find((item) => item.value === state?.selected?.redeemStatusID);
    setRedeemStatus(result);
  },[state?.selected?.redeemStatusID]);

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      handleClose();
      setRedeemStatus({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={!_.isEmpty(state.selected)}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Update Redeem Status
        </DialogTitle>
        <DialogContent dividers >
          <Box sx={boxStyle}>
            <LoadingButton loading={loading2} 
              sx={{ minWidth: 250 }} 
              onClick={onSelectUserID}>
              USER ID: {state?.selected?.userID}
            </LoadingButton>
            <TextField
              disabled
              label={"Gold Amount"}
              defaultValue={state?.selected?.goldAmount}
              sx={{ minWidth: 250 }} />
          </Box>
          <Box sx={boxStyle}>
            <TextField
              disabled
              label={"Redeem Type"}
              defaultValue={redeemTypeName(state?.selected?.redeemTypeID)}
              sx={{ minWidth: 250 }} />
            <CoinType value={state?.selected?.redeemStatusID} onChangeValue={setRedeemStatus} />
          </Box>
          <PromotionItem />
          <GiftCardItem />
          <BankDetails />
          <PaypalDetails />
          <ProofOfIDs />
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} onClick={handleUpdate}>
            Update
          </LoadingButton>
          <Button disabled={loading} autoFocus sx={{color: 'text.secondary'}} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <UserDetailsDialog visible={!_.isEmpty(user)}
        user={user}
        onClose={() => setUser(null)} />
    </div>
  );
}