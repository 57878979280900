import React from 'react';
import DatePickerScreen from '@src/components/date-picker';
import ProviderName from './provider-name';
import { ProgramContext } from './provider';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';

const FilterSection = () => {
  const { state, loading, onDispatch, onGenerate } = React.useContext(ProgramContext);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', height: 100, pr: 3 }}>
      <ProviderName value={state.providerName} onChange={onDispatch("providerName")} />
      <DatePickerScreen label="From" 
        sx={{mr: 3}} 
        value={state.from_date} 
        onChange={onDispatch("from_date")} />
      <DatePickerScreen label="To" 
        value={state.to_date} 
        onChange={onDispatch("to_date")}/>
      <LoadingButton onClick={onGenerate}
        loading={loading}
        variant={"contained"}
        sx={{ml: 3, height: 50}} >GENERATE</LoadingButton>
    </Box>
  );
}

export default FilterSection;
