import React, { createContext, useCallback } from 'react';
import ActionMenu from './menu';
import { useUserService } from '@src/store/hooks';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedUserState } from '@src/store/slices/users.slice';

interface ContextValue {
  tableContainerRef: any;
  state: any;
  onDispatch: any;
  handleScrollLeft: any;
  handleScrollRight: any;
}

export const UserContext = createContext<ContextValue>({} as ContextValue);

interface ProviderProps {
  children: React.ReactElement;
}

const UserProvider = ({ children }: ProviderProps) => {
  const tableContainerRef = React.useRef<any>(null);
  const state = useAppSelector(selectedUserState);
  const { onUpdateUserState } = useUserService();
  const { data, users, onFetchUsersMore } = useUserService();

  const onDispatch = useCallback((key: string) => (value: any) => {
    onUpdateUserState({ key, value });
  }, [onUpdateUserState]);

  const handleScrollLeft = useCallback(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft -= 100;
    }
  }, []);

  const handleScrollRight = useCallback(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft += 100;
    }
  }, []);

  React.useEffect(() => {
    if(data.hasNext){
      onFetchUsersMore({
        "deleted": 0,
        "page": (users.length / 50) + 1,
        "size": 50,
        "sort":"enrollmentDate,desc"
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data.hasNext, users]);

  const value = {
    tableContainerRef,
    state, onDispatch,
    handleScrollLeft,
    handleScrollRight
  };

  return (
    <UserContext.Provider value={value}>
      {children}
      <ActionMenu selectedUser={state.selectedUser} 
        open={state.open} handleCloseMenu={onDispatch('open')} />
    </UserContext.Provider>
  );
};

export default UserProvider;
