import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import Iconify from '@src/components/iconify/Iconify';

const style = {
  position: 'absolute' as 'absolute',
  top: '10%',
  left: '50%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  transform: 'translate(-50%, -50%)',
  minWidth: 150,
  bgcolor: '#212221',
  radius: '2px',
  pt: 2,
  px: 4,
  pb: 2,
};

export default function SuccessModal(props: any) {

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
    >
      <Box borderRadius={1} sx={{ ...style, }}>
        
        <Typography variant="h6" color="white" mr={2}> Success</Typography>
        <Iconify
          icon="eva:checkmark-fill"
          width={30}
          sx={{
            opacity: 1,            
            color: 'common.white',
          }}
        />
      </Box>
    </Modal>
  );
}