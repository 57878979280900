import PurchaseProvider from './provider';
import MainScreen from './main';

const PurchaseScreen = () => {

  return (
    <PurchaseProvider>
      <MainScreen />
    </PurchaseProvider>
  );
}

export default PurchaseScreen;
