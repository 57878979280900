// DUCKS pattern
import { createAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { RootState } from '../index';
import * as Types from '../types';

export interface StateValue {
  loading: boolean;
  uploading: boolean;
  data: Types.GamesValue[];
  editsuccess:  any,
  error: Types.Error;
  uploadError: Types.Error;
}

const initialState: StateValue = {
  loading: false,
  uploading: false,
  data: [],
  editsuccess: {},
  error: {} as Types.Error,
  uploadError: {} as Types.Error,
} as StateValue;

// Slice
export const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    gamesRequest: (state) => {
      state.data = [];
      state.loading = true;
    },
    gamesSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    gamesFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    editRequest: (state) => {
      state.loading = true;
    },
    editSuccess: (state, action) => {
      state.editsuccess = action.payload;
      const newArray = _.map(state.data, item =>
        _.isEqual(item.code, action.payload.code) ? _.cloneDeep(action.payload) : item
      );
      state.data = newArray;
      state.loading = false;
    },
    editFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    // upload thumbnail
    uploadThumbnailRequest: (state) => {
      state.uploading = true;
    },
    uploadThumbnailSuccess: (state, action) => {
      state.uploading = false;
    },
    uploadThumbnailFailure: (state, action) => {
      state.error = action.payload;
      state.uploading = false;
    },
  },
});

// Actions
export const gamesActions = {
  gamesRequest: createAction(
    `${gamesSlice.name}/gamesRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  gamesSuccess: gamesSlice.actions.gamesSuccess,
  gamesFailure: gamesSlice.actions.gamesFailure,

  editRequest: createAction(
    `${gamesSlice.name}/editRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  editSuccess: gamesSlice.actions.editSuccess,
  editFailure: gamesSlice.actions.editFailure,

  uploadThumbnailRequest: createAction(
    `${gamesSlice.name}/uploadThumbnailRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  uploadThumbnailSuccess: gamesSlice.actions.uploadThumbnailSuccess,
  uploadThumbnailFailure: gamesSlice.actions.uploadThumbnailFailure,
};

// Selectors
export const selectedGamesLoading = (state: RootState) => state.games.loading;
export const selectedGamesUploading = (state: RootState) => state.games.uploading;
export const selectedGamesData = (state: RootState) => state.games.data;
export const selectedGamesFailed = (state: RootState) => state.games.error;
export const selectedGamesTotal = (state: RootState) => state.games.data.length;

// Reducer
export default gamesSlice.reducer;
