import React from 'react'
import { TextField } from '@mui/material'

function TextView(props: any) {
  return (
    <TextField
      label={props.label}
      disabled={props.disabled}
      value={props.value}
      onChange={props.onChange}
      onClick={props.onClick}
      sx={{
        marginTop: 2, 
        marginRight: 2,
        minWidth: 250,
        ...props.sx
      }}
      InputProps={ props.InputProps || {
        readOnly: true,
      }}
      type={props.type}
    />
  )
}

export default TextView;