/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { selectedAuthToken } from '@src/store/slices/auth.slice';
import { validation } from '@src/utils/validation-helper';
import { useInputHelper } from '@src/utils/inputs-helper';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useAppSelector } from '@src/store/hooks/hooks';
import DialogTitle from '@mui/material/DialogTitle';
import { HeaderContext } from '../header/provider';
import { useFetchGet } from '@src/utils/api/hooks';
import { TextField, Box, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { createUser } from '@src/utils/api';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { LoadingButton } from '@mui/lab';
import TierLevel from './user-type';
import validator from 'validator';
import _ from 'lodash';
import SuccessModal from './success-modal';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const boxStyle = { display: 'flex', flex: 1, flexDirection: 'row', 
   justifyContent: 'space-around', marginTop: 2 };
  
const initialState = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  password: "",
  cpassword: "",
  referral: "",
  changeOnNextLogon: 0,
  userTypeID: 1,
  gender: "",
  country: "",
  apiKey: "",
}

export default function CreateUserDialog() {
  const [error, setError] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const { state, onDispatch } = useInputHelper(initialState);
  const { createUserVisible, setCreateUserVisible } = React.useContext(HeaderContext)
  
  const handleClose = () => setCreateUserVisible(false);

  const onChange = (type: string) => (e: any) => {
    onDispatch(type)(e.target.value);
  }

  const handleCreate = async() => {
    try{
      const fields = ["firstName", "lastName", "emailAddress", "password", "cpassword"];
      const newErr = validation(fields, state);

      if(!validator.isEmail(state.emailAddress)){
        newErr.emailAddress = "Required";
      }

      if(state.password !== state.cpassword){
        newErr.cpassword = "Password does not match.";
      }

      setError(newErr);

      if(_.isEmpty(newErr)){
        setLoading(true);
        await createUser(state);
        setSuccess(true);
        setLoading(false);
      }
    }catch(e: any){
      console.log("eee", e)
      const message = e.message || e.error || "something went wrong";
      setError({ emailAddress: message })
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if(success){
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        handleClose();
        setSuccess(false);
      },1200)
    }
  },[success]);

  return (
    <>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        open={createUserVisible}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Create User
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={boxStyle}>
            <TextField
              label={"First Name"}
              value={state.firstName}
              sx={{ minWidth: 250 }}
              helperText={error.firstName}
              error={!_.isEmpty(error.firstName)}
              onChange={onChange("firstName")}
            />
            <TextField
              label={"Last Name"}
              value={state.lastName}
              sx={{ minWidth: 250 }}
              error={!_.isEmpty(error.lastName)}
              helperText={error.lastName}
              onChange={onChange("lastName")}
            />
          </Box>
          <Box sx={boxStyle}>
            <TextField
              label={"Email Address"}
              value={state.emailAddress}
              sx={{ minWidth: 250 }}
              error={!_.isEmpty(error.emailAddress)}
              helperText={error.emailAddress}
              onChange={onChange("emailAddress")}
            />
            <TierLevel onChangeValue={onDispatch("userTypeID")} />
          </Box>
          <Box sx={boxStyle}>
            <TextField
              label={"Password"}
              value={state.password}
              sx={{ minWidth: 250 }}
              helperText={error.password}
              error={!_.isEmpty(error.password)}
              onChange={onChange("password")}
            />
            <TextField
              label={"Confirm Password"}
              value={state.cpassword}
              sx={{ minWidth: 250 }}
              helperText={error.cpassword}
              error={!_.isEmpty(error.cpassword)}
              onChange={onChange("cpassword")}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} onClick={handleCreate}>
            Create
          </LoadingButton>
          <Button disabled={loading} autoFocus 
            sx={{color: 'text.secondary'}} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <SuccessModal 
        open={success}
        onClose={() => {
          handleClose();
          setSuccess(false);
        }} />
    </>
  );
}