import { Helmet } from 'react-helmet-async';
import UpdateRedeemStatus from './update-redeem-status';
import RedeemProvider from './provider';
import Body from './body';


export default function RedeemPage() {
  return (
    <RedeemProvider>
    <>
      <Helmet>
        <title> Redeem Transactions </title>
      </Helmet>
      <Body />
      <UpdateRedeemStatus />
    </>
    </RedeemProvider>
  );
}
