import React, { createContext } from 'react';
import { useInputHelper } from '@src/utils/inputs-helper';
import ActionMenu from './menu';

const initialState = {
  open: '',
  page: 0,
  provider: 0,
  order: 'asc',
  filterName: '',
  orderBy: 'name',
  rowsPerPage: 10,
  selectedGame: {},
  gamedetails: {},
}
interface ContextValue {
  state: any;
  onDispatch: any;
}

export const GamesContext = createContext<ContextValue>({} as ContextValue);

interface ProviderProps {
  children: React.ReactElement;
}

const GamesProvider = ({ children }: ProviderProps) => {
  const { state, onDispatch } = useInputHelper(initialState);

  const value = {
    state, onDispatch,
  };

  return (
    <GamesContext.Provider value={value}>
      {children}
      <ActionMenu selectedGame={state.selectedGame} 
        open={state.open} handleCloseMenu={onDispatch('open')} />
    </GamesContext.Provider>
  );
};

export default GamesProvider;
