import * as React from 'react';
import { RedeemContext } from '../provider';
import { Box, Button, Typography } from '@mui/material';
import { useFetchGet } from '@src/utils/api/hooks';
import { fileName } from '@src/utils/transform-helper';

const boxStyle = { display: 'flex', flex: 1, flexDirection: "column",
   justifyContent: 'flex-start', marginTop: 0, p: 2 };
const boxRow = { display: 'flex', flex: 1, flexDirection: "row",
   justifyContent: 'flex-start', alignItems: 'center', mt: 0, };

const ProofOfIDs = () => {
  const { data, runRequest } = useFetchGet();
  const { state } = React.useContext(RedeemContext);

  const openAttachment = (url: string) => {
    const win: any = window.open(url, '_blank');
    win.focus();
  }

  React.useEffect(() => {
    runRequest("/api/v1/redeem/userDocument/get?userid=" + state?.selected?.userID);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(![1,2,3].includes(state?.selected?.redeemTypeID)){
    return null;
  }

  return (
    <>
      <Typography sx={{ m: 0, pl: 2, pt: 2, fontSize: 14, fontWeight: "600" }}>
        PROOF OF ID ATTACHMENTS
      </Typography>
      <Box sx={boxStyle}>
        {(data || []).map((item: any) => {
          return (
            <Box key={item.id} sx={boxRow}>
              <Typography sx={{ fontSize: 14, fontWeight: "500" }}>
                {fileName(item.filePath)}
              </Typography>
              <Button onClick={() => openAttachment(item.filePath)} sx={{ fontSize: 9, ml: 1 }}>
                OPEN ATTACHMENT
              </Button>
            </Box>
          )
        })}
      </Box>
    </>
  );
}

export default ProofOfIDs;