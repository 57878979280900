/**
 * Environment variables
 */

type ConfigValues = {
  API_BASE_URL: string;
  AUTH_USERNAME: string;
  AUTH_PASSWORD: string;
  NODE_ENV: string;
  isProd: () => boolean;
  isDev: () => boolean;
  isTest: () => boolean;
};

const Config = {
  API_BASE_URL: process.env.REACT_APP_URL,
  AUTH_USERNAME: process.env.REACT_APP_AUTH_USERNAME,
  AUTH_PASSWORD: process.env.REACT_APP_AUTH_PASSWORD,
  NODE_ENV: process.env.REACT_APP_NODE_ENV,

  isProd() {
    return process.env.REACT_APP_NODE_ENV === 'production';
  },
  isDev() {
    return process.env.REACT_APP_NODE_ENV === 'development';
  },
  isTest() {
    return process.env.REACT_APP_NODE_ENV === 'test';
  },
} as ConfigValues;

export default Config;
