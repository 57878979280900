import { filter } from 'lodash';
import { ACCOUNT_TYPE, COUNTRIES, GENDER, STATUS } from './mockdata/menu-options';

const descendingComparator = (a: any, b: any, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export const getComparator = (order: any, orderBy: string) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

export const applyFilterByGameTypeId = (array: any[], gameTypeID: number) => {
  return filter(array, (item: any) => {

    if(gameTypeID === 0){
      return item;
    }else if(gameTypeID === 1 && [1,2,3].includes(item.gameTypeID)){
      return item;
    }else if(item.gameTypeID === gameTypeID){
      return item;
    }
  })
}

export const applySortFilter = (array: any, comparator: any, query: string, properties: string = 'name', properties2: string = '') => {
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
  const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => 
    `${_user[properties] || ""}`.toLowerCase().indexOf(query.toLowerCase()) !== -1 || 
    `${_user[properties2] || ""}`.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }

  return stabilizedThis.map((el: any) => el[0]);
}

const OptionValue: any = {
  1: "Paypal Web",
  2: "CreditCard web",
  3: "Bitpace Web",
  4: "Bitpace App",
  5: "Google Pay",
  6: "Braintree Paypal",
  7: "Braintree CreditCard",
  8: "Apcopay CreditCard"
}

export const paymentOption = (id: number) => {
  return OptionValue[id] || "Bitpace Web";
}

export const getCountryGender = (userDetail: any = {}) => {
  return {
    country: userDetail?.country?.name ?? "",
    countryId: userDetail?.country?.id ?? 0,
    gender: userDetail?.gender?.name ?? "",
    street: userDetail?.street ?? "",
  };
}

export const getCountryValue = (id: number) => {
  const result = COUNTRIES.find((item: any) => item.value === id);

  return result || COUNTRIES[0];
}

export const getGenderValue = (genderName: string = "") => {
  const result = GENDER.find((item: any) => item.label.toLowerCase() === genderName.toLowerCase());

  return result || GENDER[0];
}

export const getStatusValue = (statusName: string = "") => {
  const result = STATUS.find((item: any) => item.label.toLowerCase() === statusName.toLowerCase());

  return result || STATUS[0];
}

export const getAccountTypeValue = (acctType: number = 1) => {
  const result = ACCOUNT_TYPE.find((item: any) => item.value === acctType);

  return result || ACCOUNT_TYPE[0];
}

export const getAmountBalanceByCoinType = (array: any) => {
  const coinTypes = ["SILVER", "GOLD", "GOLD BONUS"];
  const balanceByCoinType: any = {};

  // Initialize balance for each coin type to 0
  coinTypes.forEach((coinType) => {
    balanceByCoinType[coinType] = 0;
  });

  // Calculate the balance for each coin type
  array.forEach((item: any) => {
    const coinTypeName = item.coinType.name;
    if (coinTypes.includes(coinTypeName)) {
      balanceByCoinType[coinTypeName] += item.amount;
    }
  });

  return balanceByCoinType;
};

export const getProvider = (gameTypeID: number) => {
  switch(gameTypeID){
    case 10:
      return "MACAW";
    case 9:
      return "JDB";
    case 8:
      return "YGT";
    case 7:
      return "RGS";
    case 6:
      return "CT";
    case 5:
      return "LIONWIN"
    case 1: case 2: case 3:
      return "EVO"
    default:
      return "N/A"
  }
}

export const CoinType: any = {
  1: "Silver",
  2: "GWz Gold",
  3: "Bonus Gold",
}

export const transformUsersWithBalance = async (array: any[]) => {
  const userPromises = array.map(async (item: any) => {
    const result = await getAmountBalanceByCoinType(item.userBalanceList || []);
    return { 
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      userTypeID: item.userTypeID,
      emailAddress: item.emailAddress,
      enrollmentDate: item.enrollmentDate,
      lastModifiedDate: item.lastModifiedDate,
      dateOfBirth: item.dateOfBirth,
      phoneNumber: item.phoneNumber,
      displayName: item.displayName,
      userDetail: item.userDetail,
      userBalanceList: item.userBalanceList,
      ...result };
  });

  const usersWithBalance = await Promise.all(userPromises);
  return usersWithBalance;
}

export const redeemTypeName = (idType: number) => {
  switch(idType){
  case 1:
    return "Bank";
  case 2:
    return "Paypal";
  case 3:
    return "Gift Card";
  case 4:
    return "Promotion Product";
  }
};

export const redeemStatusName = (status: number) => {
  switch(status){
  case 1:
    return "Request submit";
  case 2:
    return "Email verified";
  case 3:
    return "Review in progress";
  case 4:
    return "Request approved";
  case 5:
    return "Funded to User";
  case 6:
    return "Request rejected";
  }
};

export const tierLevelStatus = (idType: number) => {
  switch(idType){
  case 1:
    return "Sapphire";
  case 2:
    return "Emerald";
  case 3:
    return "Ruby";
  case 4:
    return "Diamond";
  }
};

export const gameCategory = (gameCategoryID: number) => {
  switch(gameCategoryID){
    case 1:
      return "Slot";
    case 2:
      return "Table";
    case 3:
      return "Instant";
    case 4:
      return "RPG";
    case 5:
      return "Crash";
    case 6:
      return "Fishing";
    case 7:
      return "Other";
    default:
      return gameCategoryID;
  }
}
