import _ from "lodash";

export const validation = (requiredFields: any, stateInput: any) => {
    const newError: any = {};
    const ArrayConvert = _.isArray(requiredFields)
      ? requiredFields
      : Object.keys(requiredFields);
    ArrayConvert.forEach((field: any) => {
      if (!stateInput[field]) {
        newError[field] = "This field is required";
      }
    });

    return newError;
  }