import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery, delay, select } from 'redux-saga/effects';
import _reduce from 'lodash/reduce';
import numeral from 'numeral';
import moment from 'moment';
import { downloadExcel } from '@src/utils/download-helper';
import { transformUsersWithBalance } from '@src/utils/filter-helper';

import { CoinType } from '../../utils/filter-helper';

// Types
// import * as Types from '../types';

// API
import * as API from '../../utils/api';

// Slice
import { authActions, selectedAuthToken } from '../slices/auth.slice';
import { usersActions } from '../slices/users.slice';
import { gamesActions } from '../slices/games.slice';
import { purchaseActions } from '../slices/purchase.slice';
import { transactionActions } from '../slices/transaction.slice';
import { gamehistoryActions } from '../slices/gamehistory.slice';
import { coinhistoryActions } from '../slices/coinhistory.slice';
import { redeemActions } from '../slices/redeem.slice';

function* handleUsers(action: {
  type: typeof usersActions.usersRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const users = yield call(API.getAllUsers, action.payload, token);

    const usersWithBalance = yield call(transformUsersWithBalance, users.data.items);

    yield put(usersActions.usersSuccess({...users.data, items: usersWithBalance}));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    if(message === "Unauthorized"){
      yield put(authActions.logout());
    }

    yield put(usersActions.usersFailure({ message }));
    yield delay(1000);
    yield put(usersActions.usersFailure({}));
  }
}

function* handleRefreshUsers(action: {
  type: typeof usersActions.usersRefreshRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);

    const users = yield call(API.getAllUsers, action.payload, token);

    const usersWithBalance = yield call(transformUsersWithBalance, users.data.items);

    yield put(usersActions.usersRefreshSuccess({...users.data, items: usersWithBalance}));

  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    if(message === "Unauthorized"){
      yield put(authActions.logout());
    }

    yield put(usersActions.usersRefreshFailure({ message }));
    yield delay(1000);
    yield put(usersActions.usersRefreshFailure({}));
  }
}

function* handleSearchUser(action: {
  type: typeof usersActions.searchUserRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const users = yield call(API.getAllUsers, action.payload, token);

    const usersWithBalance = yield call(transformUsersWithBalance, users.data.items);

    yield put(usersActions.searchUserSuccess({...users.data, items: usersWithBalance}));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    if(message === "Unauthorized"){
      yield put(authActions.logout());
    }

    yield put(usersActions.searchUserFailure({ message }));
    yield delay(1000);
    yield put(usersActions.searchUserFailure({}));
  }
}

function* handleUserBalanceUpdate(action: {
  type: typeof usersActions.updateBalanceRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const result = yield call(API.updateUserBalance, action.payload, token);

    yield put(usersActions.updateBalanceSuccess({ userID: action.payload.userID, userBalanceList: [result.data]}));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    if(message === "Unauthorized"){
      yield put(authActions.logout());
    }

    yield put(usersActions.updateBalanceFailure({ message }));
    yield delay(1000);
    yield put(usersActions.updateBalanceFailure({}));
  }
}

function* handlePurchase(action: {
  type: typeof purchaseActions.purchaseRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const purchase = yield call(API.getAllTransactions, action.payload, token);

    yield put(purchaseActions.purchaseSuccess(purchase.data));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    yield put(purchaseActions.purchaseFailure({ message }));
    yield delay(1000);
    yield put(purchaseActions.purchaseFailure({}));
  }
}

function* handleGames(action: {
  type: typeof gamesActions.gamesRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const games = yield call(API.gameList, action.payload, token);

    yield put(gamesActions.gamesSuccess(games));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    if(message === "Unauthorized"){
      yield put(authActions.logout());
    }

    yield put(gamesActions.gamesFailure({ message }));
    yield delay(1000);
    yield put(gamesActions.gamesFailure({}));
  }
}

function* handleEditGames(action: {
  type: typeof gamesActions.editRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    yield call(API.editGameDetails, action.payload, token);

    yield put(gamesActions.editSuccess(action.payload));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    if(message === "Unauthorized"){
      yield put(authActions.logout());
    }

    yield put(gamesActions.editFailure({ message }));
    yield delay(1000);
    yield put(gamesActions.editFailure({}));
  }
}

function* handleGameThumbnail(action: {
  type: typeof gamesActions.uploadThumbnailRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);

    const res = yield call(API.uploadGameThumbnail, action.payload, token);

    const game = res.body.data;

    yield put(gamesActions.uploadThumbnailSuccess(game));

    if (action.payload.callback && typeof action.payload.callback === 'function') {
      yield call(action.payload.callback, game.variants?.[0])
    }
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    if(message === "Unauthorized"){
      yield put(authActions.logout());
    }

    yield put(gamesActions.uploadThumbnailFailure({ message }));
    yield delay(1000);
    yield put(gamesActions.uploadThumbnailFailure({}));
  }
}

function* handleTransaction(action: {
  type: typeof transactionActions.transactionRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const transaction = yield call(API.getPlayResultSummary, action.payload, token);
    const isFetchMore = action.payload?.isFetchMore ?? false;

    yield put(transactionActions.transactionSuccess({...transaction.data, isFetchMore}));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    yield put(transactionActions.transactionFailure({ message }));
    yield delay(1000);
    yield put(transactionActions.transactionFailure({}));
  }
}

function* handleGameHistory(action: {
  type: typeof gamehistoryActions.gamehistoryRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const gamehistory = yield call(API.getPlayResult, action.payload, token);
    const isFetchMore = action.payload?.isFetchMore ?? false;

    yield put(gamehistoryActions.gamehistorySuccess({...gamehistory.data, isFetchMore}));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    yield put(gamehistoryActions.gamehistoryFailure({ message }));
    yield delay(1000);
    yield put(gamehistoryActions.gamehistoryFailure({}));
  }
}

function* handleGameHistoryExport(action: {
  type: typeof gamehistoryActions.gamehistoryRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const gamehistory = yield call(API.getPlayResultExport, action.payload, token);

    const exportData = () => {
      const newJson = _reduce(gamehistory.items, (result: any, value: any, key) => {
        result.push({
          'Game ID': value.gameId,
          'Round ID': value.roundID,
          'Action Type': value.callbackActionType,
          'Currency': value.currency,
          'Total Bet': numeral(value.totalBet).format("0,000.00"),
          'Total Win': numeral(value.totalWin).format("0,000.00"),
          'CreatedAt': value.createdDate ? moment(value.createdDate).format("MM-DD-YYYY hh:mm A") : null
        });
  
        return result;
      }, []);
  
      downloadExcel(newJson, 'export-user-game-history')
    }

    yield call(exportData);

    yield put(gamehistoryActions.gamehistoryExportSuccess({...gamehistory.data }));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    yield put(gamehistoryActions.gamehistoryExportFailure({ message }));
    yield delay(1000);
    yield put(gamehistoryActions.gamehistoryExportFailure({}));
  }
}

function* handleCoinHistory(action: {
  type: typeof coinhistoryActions.coinhistoryRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const coinhistory = yield call(API.getCoinTransactions, action.payload, token);
    const isFetchMore = action.payload?.isFetchMore ?? false;

    yield put(coinhistoryActions.coinhistorySuccess({...coinhistory.data, isFetchMore}));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    yield put(coinhistoryActions.coinhistoryFailure({ message }));
    yield delay(1000);
    yield put(coinhistoryActions.coinhistoryFailure({}));
  }
}

function* handleCoinHistoryExport(action: {
  type: typeof coinhistoryActions.coinhistoryExportRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const coinhistory = yield call(API.getCoinTransactionsExport, action.payload, token);

    const exportData = () => {
      const newJson = _reduce(coinhistory.items, (result: any, value: any, key) => {
        result.push({
          'ID': value.id,
          'Coin Type': CoinType[value.coinTypeId],
          'Transaction': value.description,
          'Amount': numeral(value.amount).format("0,000.00"),
          'Balance Before': numeral(value.balanceBefore).format("0,000.00"),
          'Balance After': numeral(value.balanceAfter).format("0,000.00"),
          'CreatedAt': moment(value.enrollmentDate).format("MM-DD-YYYY hh:mm A")
        });
  
        return result;
      }, []);
  
      downloadExcel(newJson, 'export-user-coin-history')
    }

    yield call(exportData);

    yield put(coinhistoryActions.coinhistoryExportSuccess({...coinhistory.data }));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    yield put(coinhistoryActions.coinhistoryExportFailure({ message }));
    yield delay(1000);
    yield put(coinhistoryActions.coinhistoryExportFailure({}));
  }
}

function* handleRedeemRequest(action: {
  type: typeof redeemActions.redeemRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const redeem = yield call(API.getRedeemTransaction, action.payload, token);

    yield put(redeemActions.redeemSuccess(redeem));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    yield put(redeemActions.redeemFailure({ message }));
    yield delay(1000);
    yield put(redeemActions.redeemFailure({}));
  }
}

function* handleRedeemUpdate(action: {
  type: typeof redeemActions.updateRedeemRequest;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const redeem = yield call(API.updateRedeemStatus, action.payload, token);

    yield put(redeemActions.updateRedeemSuccess({
      ...redeem, 
      redeemStatusID: redeem.redeemStatus.id,
      redeemTypeID: redeem.redeemType.id
    }));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    yield put(redeemActions.updateRedeemFailure({ message }));
    yield delay(1000);
    yield put(redeemActions.updateRedeemFailure({}));
  }
}

// Watcher Saga
function* authWatcherSaga(): SagaIterator {
  yield takeEvery(usersActions.usersRequest.type, handleUsers);
  yield takeEvery(usersActions.usersRefreshRequest.type, handleRefreshUsers);
  yield takeEvery(usersActions.searchUserRequest.type, handleSearchUser);
  yield takeEvery(usersActions.updateBalanceRequest.type, handleUserBalanceUpdate);

  yield takeEvery(gamesActions.gamesRequest.type, handleGames);
  yield takeEvery(gamesActions.editRequest.type, handleEditGames);
  yield takeEvery(gamesActions.uploadThumbnailRequest.type, handleGameThumbnail);

  yield takeEvery(purchaseActions.purchaseRequest.type, handlePurchase);

  yield takeEvery(transactionActions.transactionRequest.type, handleTransaction);

  yield takeEvery(gamehistoryActions.gamehistoryRequest.type, handleGameHistory);
  yield takeEvery(gamehistoryActions.gamehistoryExportRequest.type, handleGameHistoryExport);
  yield takeEvery(coinhistoryActions.coinhistoryRequest.type, handleCoinHistory);
  yield takeEvery(coinhistoryActions.coinhistoryExportRequest.type, handleCoinHistoryExport);
  yield takeEvery(redeemActions.redeemRequest.type, handleRedeemRequest);
  yield takeEvery(redeemActions.updateRedeemRequest.type, handleRedeemUpdate);
}

export default authWatcherSaga;
