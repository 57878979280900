import React from 'react';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import validator from "validator";
import numeral from 'numeral';
import _ from 'lodash';

import { getAmountBalanceByCoinType } from '@src/utils/filter-helper';
import { selectedUserItems, selectedUsersLoadingSearch } from '@src/store/slices/users.slice';
import ButtonExport from '@src/components/buttons/button-export';
import { downloadExcel } from '@src/utils/download-helper';
import { useAppSelector } from '@src/store/hooks/hooks';
import Iconify from '@src/components/iconify/Iconify';
import { useUserService } from '@src/store/hooks';
import { SearchObj } from '@src/utils/api/types';

import { StyledRoot, StyledSearch, StyledWrap } from '../styles.css';

import { UsersListToolbarProps } from './user.types';

import UserFilterSidebar from './UserFilterSidebar';

export default function UserListToolbar({ onSearch, onRefresh }: UsersListToolbarProps) {
  const timeout = React.useRef<any>();
  const { onSearchUser } = useUserService();
  const [search, setSearch] = React.useState("");
  const users = useAppSelector(selectedUserItems);
  const loading = useAppSelector(selectedUsersLoadingSearch);
  const [openFilter, setOpenFilter] = React.useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  }

  const onExport = () => {
    const newJson = _.reduce(users, (result: any, value: any, key) => {
      const coins = getAmountBalanceByCoinType(value.userBalanceList || []);

      result.push({
        id: value.id,
        'First Name': value.firstName,
        'Last Name': value.lastName,
        'Email': value.emailAddress,
        'Display Name': value.displayName,
        'Silver Balance': numeral(coins.SILVER).format("0,000"),
        'GWz Gold': numeral(coins.GOLD).format("0,000.00"),
        'Gold Balance': numeral(coins["GOLD BONUS"]).format("0,000.00"),
        'CreatedAt': value.enrollmentDate,
        'Status': value.isDeleted ? "Deactivated" : "Active",
      })

      return result;
    }, []);
    downloadExcel(newJson, 'export-user');
  };

  React.useEffect(() => {
    timeout.current && clearTimeout(timeout.current);

    if (search) {
      timeout.current = setTimeout(() => {
        clearTimeout(timeout.current);
        onSearch(search);

        const searchObj: SearchObj = {
          "page": 1,
          "size": 50,
          "sort": "enrollmentDate,desc"
        };

        if (validator.isEmail(search)) {
          searchObj.email = search;
        }

        if (validator.isInt(search)) {
          searchObj.userID = parseInt(search, 10);
        }

        onSearchUser(searchObj);
      }, 1000);
    }

    if (!search) {
      onSearch(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <StyledRoot >
      <StyledSearch
        value={search}
        onChange={handleSearch}
        placeholder="Search User ID or Email"
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
        endAdornment={
          loading &&
          <InputAdornment position="start">
            <CircularProgress size={20} />
          </InputAdornment>
        }
      />
      <StyledWrap>
        <UserFilterSidebar
          openFilter={openFilter}
          onOpenFilter={handleOpenFilter}
          onCloseFilter={handleCloseFilter}
        />
        <IconButton aria-label="delete" color="primary" onClick={onRefresh}>
          <Iconify icon={'mdi:refresh'} width={30} />
        </IconButton>
        <ButtonExport sx={{ marginLeft: 5 }} onClick={onExport} />
      </StyledWrap>
    </StyledRoot>
  );
}
