import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { usePurchaseService, useRedeemService, useUserService } from '@src/store/hooks';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }: any) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item: any) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }: any) {
  const { title, path, icon, info } = item;
  const {onFetchUsersMore} = useUserService(); 
  const {onFetchRedeemMore} = useRedeemService(); 
  const {onFetchPurchaseMore} = usePurchaseService(); 

  const onPress = () => {
    switch(title){
      case "users":
        // onFetchUsersMore({
        //   filter: true,
        //   "deleted": 0,
        //   "page": 1,
        //   "size": 50,
        //   "sort":"enrollmentDate,desc"
        // });
        break;
      case "redeem transactions":
        onFetchRedeemMore({
          filter: true,
          "page": 1,
          "size": 50,
          "sort":"requestDate,desc"
        });
        break;
      case "purchase":
        onFetchPurchaseMore({
          filter: true,
          "payment_type_id":"",
          "status":"",
          "page": 1,
          "size": 50,
          "sort":"transactionDate,desc"
        });
        break;
    }
  }

  return (
    <StyledNavItem
      onClick={onPress}
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </StyledNavItem>
  );
}
