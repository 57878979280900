import { useState } from 'react';
// @mui
import { Menu, Button, MenuItem, Typography, Box } from '@mui/material';
// component
import Iconify from '@src/components/iconify/Iconify';

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { label: 'Evo' },
  { label: 'LION Games' },
  { label: 'CT Games' },
  { label: '2YC Games' },
  { label: 'YGT Games' },
];

export default function ProviderName(props: any) {
  const [open, setOpen] = useState(null);
  const [selected, setSelected] = useState(SORT_BY_OPTIONS[0]);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onSelectionChange = (value: any) => {
    setOpen(null);
    setSelected(value);
    props.onChange(value.label);
  };

  return (
    <>
      <Button
        color="inherit"
        sx={{display: 'flex', minWidth: 200, height: 55, justifyContent: 'space-between', 
        border: 0.7, borderColor: 'rgba(0,0,0,0.2)', color: 'text.secondary', mr: 3}}
        onClick={handleOpen}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        <Box>
          Provider Name:
          <Typography component="span" variant="subtitle2" sx={{ color: 'text.primary', marginLeft: 1, }}>
            {selected.label}
          </Typography>
        </Box>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            selected={option.label === selected.label}
            onClick={() => onSelectionChange(option)}
            sx={{ typography: 'body2' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
