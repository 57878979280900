import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  selectedAuthToken, selectedAuthUserId 
} from '../slices/auth.slice';


// Types
import * as Types from '../types';
import { selectedGamesData, selectedGamesFailed, selectedGamesLoading, gamesActions, selectedGamesUploading} from '../slices/games.slice';
import moment from 'moment';

type ServiceOperators = {
  games: Types.GamesValue[];
  isLoading: boolean;
  isUploading: boolean;
  failed: Types.Error;
  onFetchGames: () => void;
  onFetchGamesCategory: (params: any) => void;
  onEditGameDetails: (params: any) => void;
  onUploadGameThumbnail: (params: any) => void;
};

export const useGamesService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectedAuthToken);
  const userID = useAppSelector(selectedAuthUserId);

  return {
    games: useAppSelector(selectedGamesData),
    failed: useAppSelector(selectedGamesFailed),
    isLoading: useAppSelector(selectedGamesLoading),
    isUploading: useAppSelector(selectedGamesUploading),
    onFetchGames: useCallback(
      () => {
        dispatch(gamesActions.gamesRequest({ 
          token,
          userID,
          gameTypeId: 0,
        }));
      },
      [dispatch, userID, token]
    ),
    onFetchGamesCategory: useCallback(
      (gameTypeId: number) => {
        dispatch(gamesActions.gamesRequest({
          token,
          userID,
          gameTypeId,
        }));
      },
      [dispatch, userID, token]
    ),
    onEditGameDetails: useCallback(
      (params: any) => {
        dispatch(gamesActions.editRequest({
          ...params,
          isActive: Number(params.isActive),
          isTop: Number(params.isTop),
          tierID: Number(params.tierID),
          lastModifiedDate: moment().format(),
        }));
      },
      [dispatch]
    ),
    onUploadGameThumbnail: useCallback(
      (params: any) => {
        dispatch(gamesActions.uploadThumbnailRequest({ ...params }));
      },
      [dispatch]
    ),
  };
};

export default useGamesService;
