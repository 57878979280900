import {
  TableRow,
  TableCell,
  Stack,
  Typography,
  Button
} from '@mui/material';
import moment from 'moment';
import { paymentOption } from '@src/utils/filter-helper';
import { useNavigate } from 'react-router-dom';
import { useUserService } from '@src/store/hooks';

const TableRowItem = (props: any) => {
  const navigate = useNavigate();
  const { onFetchUsersMore } = useUserService();
  const onViewUserDetails = () => {
    navigate("/dashboard/users");
    onFetchUsersMore({
      userID: props.userID,
      filter: true,
      "page": 1,
      "size": 50,
      "sort":"enrollmentDate,desc"
    });
  }

  return (
    <TableRow hover tabIndex={-1} role="checkbox" >
      <TableCell onClick={onViewUserDetails} component="th" scope="row" padding="none">
        <Stack direction="row" alignItems="center" ml={2} spacing={1}>
          <Button>
          <Typography variant="subtitle2" noWrap sx={{textDecoration: 'underline'}}>
            {props.userID}
          </Typography>
          </Button>
        </Stack>
      </TableCell>
      <TableCell align="left">{props.emailAddress}</TableCell>
      <TableCell align="left">{props.purchaseItem}</TableCell>
      <TableCell align="left">{props.amount}</TableCell>
      <TableCell align="left">{paymentOption(props.paymentTypeID)}</TableCell>
      <TableCell align="left">{props.status}</TableCell>
      <TableCell align="left">{moment(props.transactionDate).format("MM-DD-YYYY hh:mm A")}</TableCell>
    </TableRow>
  );
}

export default TableRowItem;
