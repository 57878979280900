import * as React from 'react';
import { RedeemContext } from '../provider';
import { Box, TextField, Typography } from '@mui/material';

const boxStyle = { display: 'flex', flex: 1, flexDirection: 'row', 
   justifyContent: 'space-around', marginTop: 2 };

const GiftCardItem = () => {
  const { state } = React.useContext(RedeemContext);

  if(state?.selected?.redeemTypeID !== 3){
    return null;
  }

  return (
    <>
      <Typography sx={{ m: 0, p: 2, fontSize: 14, fontWeight: "600" }}>
        Gift Card Item
      </Typography>
      <Box sx={boxStyle}>
        <TextField
          disabled
          label={"Gift Card ID"}
          defaultValue={state?.selected?.giftCardID}
          sx={{ minWidth: 250 }} 
          />
        <TextField
          disabled
          label={"Gift Card Name"}
          defaultValue={state?.selected?.giftCardName}
          sx={{ minWidth: 250 }} />
      </Box>
    </>
  );
}

export default GiftCardItem;