import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import { Grid, Container, Typography } from '@mui/material';

// sections
import {
  AppWidgetSummary,
} from '../sections/@dashboard/app';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedUsersTotal } from '@src/store/slices/users.slice';
import { selectedPurchaseTotal } from '@src/store/slices/purchase.slice';
import { selectedGamesTotal } from '@src/store/slices/games.slice';
import { selectedTransactionTotal } from '@src/store/slices/transaction.slice';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const navigate = useNavigate();
  const usersTotal = useAppSelector(selectedUsersTotal);
  const gamesTotal = useAppSelector(selectedGamesTotal);
  const purchaseTotal = useAppSelector(selectedPurchaseTotal);
  const transactionTotal = useAppSelector(selectedTransactionTotal);

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Welcome back
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3} >
            <AppWidgetSummary onClick={() => navigate('/dashboard/programs')} 
              title="Programs" total={1} color="secondary" icon={'ant-design:setting'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <AppWidgetSummary onClick={() => navigate('/dashboard/users')} 
              title="Users" total={usersTotal} color="info" icon={'ant-design:user'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary onClick={() =>  navigate('/dashboard/games')}
              title="Games" total={gamesTotal} icon={'dashicons:games'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary onClick={() =>  navigate('/dashboard/purchase')}
              title="Purchase" total={purchaseTotal} color="warning" icon={'ant-design:shopping-cart-outlined'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary onClick={() =>  navigate('/dashboard/mostplayed')} 
              title="Most Played" total={transactionTotal} color="error" icon={'mdi-light:star'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary onClick={() =>  navigate('/dashboard/redeem')} 
              title="Redeem Transaction" total={transactionTotal} color="success" icon={'mdi-light:file'} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
