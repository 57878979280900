import React from 'react';
import { selectedTransactionItems } from '@src/store/slices/transaction.slice';
import { StyledRoot, StyledWrapRight } from '../styles.css';
import ButtonExport from '@src/components/buttons/button-export';
import PurchaseFilterSidebar from './TransactionFilterSidebar';
import { downloadExcel } from '@src/utils/download-helper';
import { useAppSelector } from '@src/store/hooks/hooks';
import numeral from 'numeral';
import _ from 'lodash';
import { IconButton } from '@mui/material';
import Iconify from '@src/components/iconify';
import { useTransactionService } from '@src/store/hooks';

export default function TransactionListToolbar({ placeholder, filterName, onFilterName }: any) {
  const transactions = useAppSelector(selectedTransactionItems);
  const [openFilter, setOpenFilter] = React.useState(false);
  const { onFetchTransactions } = useTransactionService();

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const onExport = () => {
    const newJson = _.reduce(transactions, (result: any, value: any, key) => {
      result.push({
        'Partner Name': value.partnerName,
        'Game Name': value.gameName,
        'Game ID': value.gameId,
        'Total Players': value.totalPlayers || 0,
        'Total Bet': numeral(value.totalBet || 0).format("0,000"),
        'Average Bet': numeral(value.averageBet || 0).format("0,000"),
        'Average Win': numeral(value.averageWin || 0).format("0,000"),
      })

      return result;
    }, []);
    downloadExcel(newJson, 'export-transaction');
  };

  const handleRefresh = () => onFetchTransactions();

  return (
    <StyledRoot >
      {/* <StyledSearch
        value={filterName}
        onChange={onFilterName}
        placeholder={placeholder || "Search email address..."}
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      /> */}
      <StyledWrapRight style={{ width: '100%' }}>
        <PurchaseFilterSidebar
          openFilter={openFilter}
          onOpenFilter={handleOpenFilter}
          onCloseFilter={handleCloseFilter}
        />
        <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
          <Iconify icon={'mdi:refresh'} width={30} />
        </IconButton>
        <ButtonExport sx={{ marginLeft: 5 }} onClick={onExport} />
      </StyledWrapRight>
    </StyledRoot>
  );
}
