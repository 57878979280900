// DUCKS pattern
import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import * as Types from '../types';
import _ from 'lodash';

const redeemState = {
  page: 0,
  order: 'desc',
  orderBy: 'id',
  filterName: '',
  fromdate: null,
  todate: null,
  rowsPerPage: 10,
  open: '',
  selected: {}
}

export interface StateValue {
  loading: boolean;
  data: Types.UsersValue;
  error: Types.Error;
  redeemState: any;
  redeemUpdate: any;
}

const initialState: StateValue = {
  loading: false,
  data: {} as Types.UsersValue,
  error: {} as Types.Error,
  redeemState
} as StateValue;

// Slice
export const redeemSlice = createSlice({
  name: 'redeem',
  initialState,
  reducers: {
    redeemRequest: (state, action) => {
      state.loading = true;
      if(action.payload.filter){
        state.data = {} as Types.UsersValue;
      }
    },
    redeemSuccess: (state, action) => {
      let items = _.unionBy(state.data?.items ?? [], action.payload.items, "id");
      state.data = { ...action.payload, items};
      state.loading = false;
    },
    redeemFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    updateRedeemRequest: (state) => {
      state.loading = true;
    },
    updateRedeemSuccess: (state, action) => {
      let items = state.data.items;
      items = items.map((i: any) => {
        if(i.id === action.payload.id){
          return action.payload;
        }
        return i;
      })

      state.redeemUpdate = action.payload;
      state.data["items"] = items;
      state.loading = false;
    },
    updateRedeemFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    updateRedeemState: (state, action) => {
      state.redeemState[action.payload.key] = action.payload.value;
    },

    redeemReset: () => {
      return initialState;
    },
  },
});

// Actions
export const redeemActions = {
  redeemRequest: createAction(
    `${redeemSlice.name}/redeemRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  redeemSuccess: redeemSlice.actions.redeemSuccess,
  redeemFailure: redeemSlice.actions.redeemFailure,

  updateRedeemRequest: createAction(
    `${ redeemSlice.name}/ updateRedeemRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  updateRedeemSuccess:  redeemSlice.actions.updateRedeemSuccess,
  updateRedeemFailure:  redeemSlice.actions.updateRedeemFailure,

  updateRedeemState: redeemSlice.actions.updateRedeemState,
  redeemReset: redeemSlice.actions.redeemReset,
};

// Selectors
export const selectedRedeemLoading = (state: RootState) => state.redeem.loading;
export const selectedRedeemData = (state: RootState) => state.redeem.data;
export const selectedRedeemFailed = (state: RootState) => state.redeem.error;
export const selectedRedeemState = (state: RootState) => state.redeem.redeemState;
export const selectedRedeemUpdate = (state: RootState) => state.redeem.redeemUpdate;
export const selectedRedeemItems = createSelector(
  (state: RootState) => state.redeem.data,
  state => state.items || []
);
export const selectedRedeemTotal = createSelector(
  (state: RootState) => state.redeem.data,
  state => state.totalRecords || 0
);

// Reducer
export default redeemSlice.reducer;
