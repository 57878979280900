import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  selectedAuthToken,
} from '../slices/auth.slice';


// Types
import * as Types from '../types';
import {
  purchaseActions,
  selectedPurchaseItems, 
  selectedPurchaseData,
  selectedPurchaseFailed, 
  selectedPurchaseLoading} from '../slices/purchase.slice';

type ServiceOperators = {
  data: Types.UsersValue;
  purchases: any;
  isLoading: boolean;
  failed: Types.Error;
  onFetchPurchase: () => void;
  onFetchPurchaseMore: (params: any) => void;
};

export const usePurchaseService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectedAuthToken);

  return {
    data: useAppSelector(selectedPurchaseData),
    purchases: useAppSelector(selectedPurchaseItems),
    failed: useAppSelector(selectedPurchaseFailed),
    isLoading: useAppSelector(selectedPurchaseLoading),
    onFetchPurchase: useCallback(
      () => {
        dispatch(purchaseActions.purchaseRequest({ 
          token, 
          "payment_type_id":"",
          "status":"",
          "page": 1,
          "size": 50,
          "sort":"transactionDate,desc"
        }));
      },
      [dispatch, token]
    ),
    onFetchPurchaseMore: useCallback(
      (params: any) => {
        dispatch(purchaseActions.purchaseRequest({
          token,
          ...params
        }));
      },
      [dispatch, token]
    ),
  };
};

export default usePurchaseService;
