/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import validator from 'validator';
// @mui
import {
  Card,
  Table,
  Stack,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
} from '@mui/material';
// components
import Scrollbar from '@src/components/scrollbar';
// sections
import { UserListToolbar } from '../../sections/@dashboard/user';
// mock
import { getComparator, applySortFilter } from '../../utils/filter-helper';
import { TABLE_HEAD_USERS } from '@src/utils/mockdata/table-headers';
import TableHeader from '@src/components/data-table/header';
import DataTableRow from '@src/components/data-table/data-row';
import Iconify from '@src/components/iconify/Iconify';
import { useUserService } from '@src/store/hooks';
import { UserContext } from './provider';
import TableRowItem from './table-row';

const Body = () => {
  const { data, users, isRefreshing, onRefreshUsers } = useUserService();
  const [search, setSearch] = React.useState("");
  const { tableContainerRef, state, onDispatch, handleScrollLeft, handleScrollRight } = useContext(UserContext);

  const handleOpenMenu = (item: any) => (event: any) => {
    onDispatch('selectedUser')(item);
    onDispatch('open')(event.currentTarget);
  };

  const handleRequestSort = (property: any) => {
    const isAsc = state.orderBy === property && state.order === 'asc';
    onDispatch('order')(isAsc ? 'desc' : 'asc');
    onDispatch('orderBy')(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    onDispatch('page')(newPage);

    // if((newPage + 1) * state.rowsPerPage >  users.length ){
    //   onFetchUsersMore({
    //     "deleted": 0,
    //     "page": newPage + 1,
    //     "size": state.rowsPerPage,
    //     "sort":"enrollmentDate,desc"
    //   });
    // }
  };

  const handleChangeRowsPerPage = (event: any) => {
    onDispatch('page')(0);
    onDispatch('rowsPerPage')(parseInt(event.target.value, 10));
  };

  const handleRefresh = () => {
    onRefreshUsers();
    handleChangePage(null, 0);
  }

  const filteredUsers = React.useMemo(() => {
    if (validator.isInt(search)) {
      return applySortFilter(users, getComparator(state.order, state.orderBy),
        search, 'id')
    }

    return applySortFilter(users, getComparator(state.order, state.orderBy),
      search, 'emailAddress')
  }, [users, search, state.order, state.orderBy]);

  const isNotFound = !filteredUsers.length && !!search;

  useEffect(() => {
    handleChangePage(null, 0);
  }, [search]);

  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
        </Stack>
        <Card>
          <UserListToolbar onSearch={setSearch} onRefresh={handleRefresh} />
          <Scrollbar>
            <TableContainer ref={tableContainerRef} sx={{ overflowX: 'auto' }}>
              <Table>
                <TableHeader
                  order={state.order}
                  orderBy={state.orderBy}
                  headLabel={TABLE_HEAD_USERS}
                  onRequestSort={handleRequestSort}
                />
                <DataTableRow
                  colSpan={9}
                  // isLoading={isLoading}
                  isRefreshing={isRefreshing}
                  isNotFound={isNotFound}
                  headers={TABLE_HEAD_USERS}
                  filterName={state.filterName}
                  data={filteredUsers.slice(state.page * state.rowsPerPage,
                    state.page * state.rowsPerPage + state.rowsPerPage)}
                  renderItem={({ item, index }: any) => {
                    return <TableRowItem key={`${index}`} {...item} handleOpenMenu={handleOpenMenu(item)} />
                  }} />
              </Table>

            </TableContainer>
          </Scrollbar>
          <TablePagination
            page={state.page}
            component="div"
            rowsPerPage={state.rowsPerPage}
            count={data?.totalRecords ?? 0}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10, 20, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <div style={{ position: 'relative', width: '100%' }}>
        <div style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
          <Button onClick={handleScrollLeft} color="secondary" >
            <Iconify icon="mdi:chevron-left" sx={{ color: 'text.black', width: 30, height: 30 }} />
          </Button>
          <Button onClick={handleScrollRight} color="secondary">
            <Iconify icon="mdi:chevron-right" sx={{ color: 'text.black', width: 30, height: 30 }} />
          </Button>
        </div>
      </div>

    </>
  );
}

export default React.memo(Body);
