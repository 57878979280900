import { Button } from '@mui/material';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectUserTypeID } from '@src/store/slices/auth.slice';

function ButtonExport(props: any) {
  const userTypeID = useAppSelector(selectUserTypeID);

  if (userTypeID === 4) {
    return null;
  }

  return (
    <Button sx={props.sx}
      {...props}
      variant={props.variant || "contained"}
      onClick={props.onClick}
    >
      {props.label || "Export"}
    </Button>
  )
}

export default ButtonExport;
