import { useContext, useState } from 'react';
// @mui
import { Menu, Button, MenuItem, Typography } from '@mui/material';
// component
import Iconify from '../../../components/iconify';
import { GamesContext } from '@src/pages/games/provider';

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: 'all', label: 'All', gameTypeId: 0 },
  { value: 'evo', label: 'Evo', gameTypeId: 1 },
  { value: 'liondragon', label: 'Lion Win', gameTypeId: 5 },
  { value: 'ctgaming', label: 'CT Games', gameTypeId: 6 },
  { value: 'rgs', label: 'RGS Games', gameTypeId: 7 },
  { value: 'ygt', label: 'YGT Games', gameTypeId: 8 },
  { value: 'jdb', label: 'JDB Games', gameTypeId: 9 },
  { value: 'macaw', label: 'MACAW Games', gameTypeId: 10 },
];

export default function GameSort() {
  const [open, setOpen] = useState(null);
  const { onDispatch } = useContext(GamesContext);
  const [selected, setSelected] = useState(SORT_BY_OPTIONS[0]);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onSelectionChange = (value: any) => {
    setOpen(null);
    setSelected(value);
    onDispatch("provider")(value.gameTypeId);
  };

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        // sx={{marginRight: 1}}
        onClick={handleOpen}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        Provider:&nbsp;
        <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {selected.label}
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === selected.value}
            onClick={() => onSelectionChange(option)}
            sx={{ typography: 'body2' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
