// DUCKS pattern
import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import * as Types from '../types';
import _ from 'lodash';

export interface StateValue {
  loading: boolean;
  exporting: boolean;
  data: Types.UsersValue;
  error: Types.Error;
  exportError?: Types.Error;
}

const initialState: StateValue = {
  loading: false,
  exporting: false,
  data: {} as Types.UsersValue,
  error: {} as Types.Error,
  exportError: {} as Types.Error,
} as StateValue;

// Slice
export const gamehistorySlice = createSlice({
  name: 'gamehistory',
  initialState,
  reducers: {
    gamehistoryRequest: (state, action) => {
      state.loading = true;
      if(action.payload.refresh){
        state.data = {} as Types.UsersValue;
      }
    },
    gamehistorySuccess: (state, action) => {
      let items = action.payload.items;
      if(action.payload.isFetchMore){
        items = _.union(state.data?.items ?? [], action.payload.items);
      }

      state.data = { ...action.payload, items};
      state.loading = false;
    },
    gamehistoryFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    gamehistoryExportRequest: (state) => {
      state.exporting = true;
    },
    gamehistoryExportSuccess: (state) => {
      state.exporting = false;
    },
    gamehistoryExportFailure: (state, action) => {
      state.exportError = action.payload;
      state.exporting = false;
    },
  },
});

// Actions
export const gamehistoryActions = {
  gamehistoryRequest: createAction(
    `${gamehistorySlice.name}/gamehistoryRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  gamehistorySuccess: gamehistorySlice.actions.gamehistorySuccess,
  gamehistoryFailure: gamehistorySlice.actions.gamehistoryFailure,

  gamehistoryExportRequest: createAction(
    `${gamehistorySlice.name}/gamehistoryExportRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  gamehistoryExportSuccess: gamehistorySlice.actions.gamehistoryExportSuccess,
  gamehistoryExportFailure: gamehistorySlice.actions.gamehistoryExportFailure,
};

// Selectors
export const selectedGameHistoryLoading = (state: RootState) => state.gamehistory.loading;
export const selectedGameHistoryExporting = (state: RootState) => state.gamehistory.exporting;
export const selectedGameHistoryData = (state: RootState) => state.gamehistory.data;
export const selectedGameHistoryFailed = (state: RootState) => state.gamehistory.error;
export const selectedGameHistoryItems = createSelector(
  (state: RootState) => state.gamehistory.data,
  state => state.items || []
);
export const selectedGameHistoryTotal = createSelector(
  (state: RootState) => state.gamehistory.data,
  state => state.totalRecords || 0
);

// Reducer
export default gamehistorySlice.reducer;
