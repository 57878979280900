// @mui
import { Card, Typography } from '@mui/material';

type ItemsProps = {
  title: string;
  value: string;
};

function Items({ title, value }: ItemsProps) {
  return (
    <Card sx={{
      py: 1,
      boxShadow: 2,
      textAlign: 'center',
      color: "black",
      bgcolor: "white",
    }}
    >
      <Typography variant="h4">{value}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}

export default Items;
