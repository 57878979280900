export const TABLE_HEAD_USERS = [
  { id: 'id', label: 'User ID' },
  { id: 'firstName', label: 'Name' },
  { id: 'emailAddress', label: 'Email' },
  { id: 'displayName', label: 'Display Name' },
  { id: 'SILVER', label: 'Silver Balance' },
  { id: 'GOLD', label: 'GWz Gold' },
  { id: 'GOLD BONUS', label: 'Gold Balance' },
  { id: 'enrollmentDate', label: 'Date Created' },
  { id: 'isDeleted', label: 'Status' },
  { id: '' },
];

export const TABLE_HEAD_GAMES = [
  { id: 'gameName', label: 'Game Name', highlight: true },
  { id: 'gameTypeID', label: 'Provider' },
  { id: 'sequence', label: 'Sequence' },
  { id: 'gameCategoryID', label: 'Game Category' },
  { id: 'code', label: 'Code' },
  { id: 'tierID', label: 'Tier ID' },
  { id: 'isActive', label: 'Status' },
  { id: 'isTop', label: 'Top Game' },
  { id: 'isGoldAllow', label: 'Gold Enable' },
  { id: 'enrollmentDate', label: 'Created At' },
  { id: 'lastModifiedDate', label: 'Updated At' },
  { id: '' },
];

export const TABLE_HEAD_PURCHASE = [
  { id: 'userID', label: 'User ID', alignRight: false },
  { id: 'emailAddress', label: 'Email Address', alignRight: false },
  { id: 'purchaseItem', label: 'Purchase Item', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'paymentTypeID', label: 'Payment Option', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'transactionDate', label: 'Transaction Date', alignRight: false },
];

export const TABLE_HEAD_TRANSACTION = [
  { id: 'partnerName', label: 'Partner Name', alignRight: false },
  { id: 'gameName', label: 'Game Name', alignRight: false },
  { id: 'gameId', label: 'Game ID', alignRight: false },
  { id: 'totalPlayers', label: 'Total Players', alignRight: false },
  { id: 'totalBet', label: 'Total Bet', alignRight: false },
  { id: 'averageBet', label: 'Average Bet', alignRight: false },
  { id: 'averageWin', label: 'Average Win', alignRight: false },
  { id: '' },
];

export const TABLE_HEAD_GAME_HISTORY = [
  { id: 'gameId', label: 'Game ID', alignRight: false },
  { id: 'roundID', label: 'Round ID', alignRight: false },
  { id: 'callbackActionType', label: 'Action Type', alignRight: false },
  { id: 'currency', label: 'Currency', alignRight: false },
  { id: 'totalBet', label: 'Total Bet', alignRight: false },
  { id: 'totalWin', label: 'Total Win', alignRight: false },
  { id: 'createdDate', label: 'Date Created', alignRight: false },
];

export const TABLE_HEAD_COIN_HISTORY = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'coinTypeId', label: 'Coin Type', alignRight: false },
  { id: 'description', label: 'Transaction', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'balanceBefore', label: 'Balance Before', alignRight: false },
  { id: 'balanceAfter', label: 'Balance After', alignRight: false },
  { id: 'enrollmentDate', label: 'Date Created', alignRight: false },
];

export const TABLE_HEAD_REDEEM_TRANSACTION = [
  { id: 'id', label: '_ID' },
  { id: 'userID', label: 'User ID' },
  { id: 'redeemTypeID', label: 'Redeem Type' },
  { id: 'redeemStatusID', label: 'Redeem Status' },
  { id: 'goldAmount', label: 'Gold Amount' },
  { id: 'requestDate', label: 'Request Date' },
  { id: '' },
];