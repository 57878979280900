import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery, delay } from 'redux-saga/effects';

// Types
import * as Types from '../types';

// API
import * as API from '../../utils/api';

// Slice
import { authActions } from '../slices/auth.slice';
import { transactionActions } from '../slices/transaction.slice';
import { usersActions } from '../slices/users.slice';

function* handleSignin(action: {
  type: typeof authActions.loginRequest;
  payload: Types.LoginInput;
}): SagaIterator {
  try {
    const auth = yield call(API.auth);
    const user = yield call(API.login, action.payload, auth.token);

    yield put(authActions.loginSuccess({...user.data, token: auth.token}));
  } catch (error: any) {
    const message = error.message?.message || error.error || error.message || 'Somthing went wrong';

    yield put(authActions.loginFailure({ message }));
    yield delay(1000);
    yield put(authActions.loginFailure({}));
  }
}

function* handleLogout(): SagaIterator {
  try {
    yield put(transactionActions.resetError());
    yield put(usersActions.userReset());
  } catch (error: any) {
  }
}

// Watcher Saga
function* authWatcherSaga(): SagaIterator {
  yield takeEvery(authActions.loginRequest.type, handleSignin);
  yield takeEvery(authActions.logout.type, handleLogout);
}

export default authWatcherSaga;
