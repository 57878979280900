import React, { createContext, useCallback } from 'react';
import { useRedeemService } from '@src/store/hooks';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedRedeemData, selectedRedeemItems, selectedRedeemState } from '@src/store/slices/redeem.slice';

interface ContextValue {
  tableContainerRef: any;
  state: any;
  onDispatch: any;
}

export const RedeemContext = createContext<ContextValue>({} as ContextValue);

interface ProviderProps {
  children: React.ReactElement;
}

const RedeemProvider = ({ children }: ProviderProps) => {
  const tableContainerRef = React.useRef<any>(null);
  const state = useAppSelector(selectedRedeemState);
  const data = useAppSelector(selectedRedeemData);
  const redeem = useAppSelector(selectedRedeemItems);
  const { onUpdateRedeemState, onFetchRedeemMore } = useRedeemService();

  const onDispatch = useCallback((key: string) => (value: any) => {
    onUpdateRedeemState({ key, value });
  }, [onUpdateRedeemState]);

  React.useEffect(() => {
    if(data.hasNext){
      onFetchRedeemMore({
        "page": (redeem.length / 50) + 1,
        "size": 50,
        "sort":"requestDate,desc"
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data.hasNext, redeem]);

  const value = {
    tableContainerRef,
    state, onDispatch,
  };

  return (
    <RedeemContext.Provider value={value}>
      {children}
    </RedeemContext.Provider>
  );
};

export default RedeemProvider;
