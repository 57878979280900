import React, { createContext, ReactElement, useState } from 'react';
import { Alert, AlertTitle, Snackbar } from '@mui/material';

interface ContextValue {
    triggerOpen: (conf: IConfig) => void
}

interface ProviderProps {
    children: ReactElement;
}

enum AlertType {
    success = 'success',
    info = 'info',
    warning = 'warning',
    error = 'error',
}

interface IConfig {
    type?: AlertType;
    title?: string;
    message: string;
}

const initialConfig = {
    type: AlertType.success,
    title: '',
    message: '',
};

const ToasterContext = createContext<ContextValue>({} as ContextValue);

const ToasterProvider = ({ children }: ProviderProps) => {
    const [open, setOpen] = useState(false);
    const [config, setConfig] = useState<IConfig>(initialConfig);

    const triggerOpen = (conf: IConfig) => {
        setOpen(true);
        setConfig(conf);
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const value = { triggerOpen };
    
    return (
        <ToasterContext.Provider value={value}>
            {children}
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert
                    onClose={handleClose}
                    severity={config.type}
                    variant="standard"
                    sx={{ width: '100%' }}
                >
                    {config.title && <AlertTitle>{config.title}</AlertTitle>}
                    {config.message}
                </Alert>
            </Snackbar>
        </ToasterContext.Provider>
    )
}

export const useToasterContext = () => {
    return React.useContext(ToasterContext);
}

export default ToasterProvider;