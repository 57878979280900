import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import Config from '../utils/api/config';
import rootReducer from './reducer';
import logger from 'redux-logger';
import rootSaga from './saga';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['auth', 'users'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);


const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: persistedReducer,
    devTools: Config.isDev(),
    middleware: (getDefaultMiddleware) => {
      if(Config.isDev()){
        return getDefaultMiddleware({ thunk: false, serializableCheck: false })
        .concat(sagaMiddleware).concat(logger);
      }

      return getDefaultMiddleware({ thunk: false, serializableCheck: false })
      .concat(sagaMiddleware);
    }
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export const store = makeStore();
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
