import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  selectedAuthToken, selectUserTypeID,
} from '../slices/auth.slice';


// Types
import * as Types from '../types';
import {selectedTransactionItems, 
  selectedTransactionData, 
  selectedTransactionFailed, 
  selectedTransactionLoading, 
  transactionActions} from '../slices/transaction.slice';

type ServiceOperators = {
  userTypeID: number;
  data: Types.UsersValue;
  transactions: any;
  isLoading: boolean;
  failed: Types.Error;
  onFetchTransactions: () => void;
  onFetchTransactionsMore: (params: any) => void;
};

export const useTransactionService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectedAuthToken);
  const userTypeID = useAppSelector(selectUserTypeID);

  return {
    userTypeID,
    data: useAppSelector(selectedTransactionData),
    transactions: useAppSelector(selectedTransactionItems),
    failed: useAppSelector(selectedTransactionFailed),
    isLoading: useAppSelector(selectedTransactionLoading),
    onFetchTransactions: useCallback(
      () => {
        dispatch(transactionActions.transactionRequest({ 
          token,
          "providerId": userTypeID === 4 ? 450 : null,
          "page": 1, 
          "size": 50,
          "sort": "CreatedDate,desc"
        }));
      },
      [dispatch, token, userTypeID]
    ),
    onFetchTransactionsMore: useCallback(
      (params: any) => {
        dispatch(transactionActions.transactionRequest({
          token,
          isFetchMore: true,
          "providerId": userTypeID === 4 ? 450 : null,
          ...params
        }));
      },
      [dispatch, token, userTypeID]
    ),
  };
};

export default useTransactionService;
