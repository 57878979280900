import * as React from 'react';
import _ from 'lodash';
import { Box, Dialog, Button, DialogContent, DialogTitle, DialogActions, IconButton, InputAdornment} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getProvider } from '@src/utils/filter-helper';
import { useGamesService } from '@src/store/hooks';
import Iconify from '@src/components/iconify';
import GameCategory from './game-category';
import { GamesContext } from '../provider';
import RadioField from './radio-group';
import TextView from './text-field';
import { useToasterContext } from '@src/context/Toaster/provider';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function GameDetailsDialog() {
  const { triggerOpen } = useToasterContext();
  const { isUploading, onEditGameDetails, onUploadGameThumbnail } = useGamesService();
  const { state, onDispatch } = React.useContext(GamesContext);
  const details = state.gamedetails;
  
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const onChangeText = (type: string) => (e: any) => {
    const newdetails = {...details};
    newdetails[type] = e.target.value;
    onDispatch("gamedetails")(newdetails);
  }

  const onChangeCategory = (type: string) => (value: any) => {
    const newdetails = {...details};
    newdetails[type] = value;
    onDispatch("gamedetails")(newdetails);
  }
  
  const handleClose = () => {
    onDispatch("gamedetails")({});
  };

  const onEdit = () => {
    onEditGameDetails(details);
    onDispatch("gamedetails")({});
  }
  
  const handleThumbnailFieldClicked = () => {
    // Trigger the click event of the hidden file input
    if (inputRef.current) {
      inputRef.current.click();
    }
  }

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    onUploadGameThumbnail({
      gameId: details.id,
      image: file,
      gameDetails: details,
      // TODO (ric): create saga middleware to handle callback after "success" and/or "failure" action types
      callback: (thumbnailUrl: string) => {
        onDispatch("gamedetails")({ ...details, thumbnailPath: thumbnailUrl });

        triggerOpen({
          message: 'Successfully uploaded game thumbnail'
        });
      }
    })
  }

  return (
    <div>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={!_.isEmpty(state.gamedetails)}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Game info
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{display: 'flex', flex: 1, 
          flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
          <Box>
            <TextView disabled label="Game Name" value={details.gameName} onClick={() => alert("s")} />
            <TextView disabled label="Provider" value={getProvider(details.gameTypeID)} />
            <TextView disabled label="Code" value={details.code} />
            <TextView label="Tier Level" type="number"
              onChange={onChangeText("tierID")}
              value={details.tierID} InputProps={{readOnly: false}} />

            <Box sx={{display: 'flex', flexDirection: 'row'}}>
              <GameCategory value={details.gameCategoryID} onChangeValue={onChangeCategory("gameCategoryID")} />
              
              <TextView
                label="Thumbnail URL"
                sx={{ml: 2}}
                onChange={onChangeText("thumbnailPath")}
                value={details.thumbnailPath}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleThumbnailFieldClicked}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <Iconify icon="eva:upload-fill" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                disabled={isUploading}
              />
              <VisuallyHiddenInput ref={inputRef} type="file" onChange={onUpload} />
            </Box>
            <TextView label="Sequence" type="number"
              onChange={onChangeText("sequence")}
              value={details.sequence} InputProps={{readOnly: false}} />
            <RadioField label="New Game?" value={details?.isNew ?? 0} 
              onChange={onChangeText("isNew")}
              data={[{label: "Yes", value: 1}, {label: "No", value: 0}]} />
            <RadioField label="For Features?" value={details?.isFeatured ?? 0} 
              onChange={onChangeText("isFeatured")}
              data={[{label: "Yes", value: 1}, {label: "No", value: 0}]} />
            <RadioField label="Top Game?" value={details?.isTop ?? 0} 
              onChange={onChangeText("isTop")}
              data={[{label: "Yes", value: 1}, {label: "No", value: 0}]} />
            <RadioField label="Status" value={details?.isActive ?? 0} 
              onChange={onChangeText("isActive")}
              data={[{label: "Active", value: 1}, {label: "Inactive", value: 0}]} />
            <RadioField label="Gold Enable?" value={details?.isGoldAllow ?? 0} 
              onChange={onChangeText("isGoldAllow")}
              data={[{label: "Yes", value: 1}, {label: "No", value: 0}]} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button autoFocus variant="contained" onClick={onEdit}>
            SAVE
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default React.memo(GameDetailsDialog);