import * as api from './request';
import * as Types from './types';
import Config from './config';

export const auth = () =>
  api.callPost('/authenticate', {
    username: Config.AUTH_USERNAME,
    password: Config.AUTH_PASSWORD,
  });

export const login = (params: Types.LoginValue, token: string) =>
  api.callPost('/api/v1/user/adminlogin', {partnerId: 1, ...params}, token);

export const createUser = (params: Types.LoginValue) =>
  api.callPost('/api/v1/user?type=1', params, 'NO TOKEN');

export const userDetails = (token: string) => api.callGet('/user/me', token);

export const gameList = (params: any, token: string) => 
  api.callGet(`/api/v1/game/list/${params.gameTypeId}?tierID=4&userID=${params.userID}`, token);

export const editGameDetails = (params: any, token: string) => 
  api.callPost(`/api/v1/game/update`,params, token);

export const uploadGameThumbnail = (params: any, token: string) => 
  api.callPost(`/api/v1/game/upload/image`, params, token, {
    "Content-Type": "multipart/form-data",
  });

export const getAllUsers = (params: any, token: string) => api.callPost('/api/v1/user/getAll', params, token);

export const updateUserBalance = (params: any, token: string) => api.callPost('/api/v1/user/adjustBalance', params, token);

export const getAllTransactions = (params: any, token: string) => api.callPost('/api/v1/payment/report/getPayment', params, token);

export const getPlayResult = (params: any, token: string) => api.callPost('/api/v1/callback/report/getPlayResult', params, token);
export const getPlayResultExport = (params: any, token: string) => api.callPost('/api/v1/callback/report/getPlayResult/noPaging', params, token);

export const getPlayResultSummary = (params: any, token: string) => api.callPost('/api/v1/callback/getPlayResultSummary', params, token);

export const getCoinTransactions = (params: any, token: string) => api.callPost('/api/v1/payment/report/getCoinTransaction', params, token);
export const getCoinTransactionsExport = (params: any, token: string) => api.callPost('/api/v1/payment/report/getCoinTransaction/noPaging', params, token);

export const getRedeemTransaction = (params: any, token: string) => api.callPost('/api/v1/redeem/get', params, token);

// REDEEM UPDATE
export const updateRedeemStatus = (params: any, token: string) => api.callPost("/api/v1/redeem/update", params, token);

// FETCH TIER INFO
export const getTierID = (params: any) => api.callGet(`/api/v1/evo/avatartier/${params.userId}`, params.token);

// UPDATE TIER LEVEL
export const updateTier = (params: any, token: string) => api.callPost(`/api/v1/user/updateTier/`, params, token);



