import * as React from 'react';
import { RedeemContext } from '../provider';
import { Box, TextField, Typography } from '@mui/material';

const boxStyle = { display: 'flex', flex: 1, flexDirection: 'row', 
   justifyContent: 'space-around', marginTop: 2 };

const BankDetails = () => {
  const { state } = React.useContext(RedeemContext);

  if(state?.selected?.redeemTypeID !== 1){
    return null;
  }

  return (
    <>
      <Typography sx={{ m: 0, p: 2, fontSize: 14, fontWeight: "600" }}>
        Bank Details
      </Typography>
      <Box sx={boxStyle}>
        <TextField
          disabled
          label={"First Name"}
          defaultValue={state?.selected?.bank_FirstName}
          sx={{ minWidth: 250 }} 
          />
        <TextField
          disabled
          label={"Last Name"}
          defaultValue={state?.selected?.bank_LastName}
          sx={{ minWidth: 250 }} />
      </Box>
      <Box sx={boxStyle}>
        <TextField
          disabled
          label={"Bank Name"}
          defaultValue={state?.selected?.bank_Name}
          sx={{ minWidth: 250 }} 
          />
        <TextField
          disabled
          label={"Account Number"}
          defaultValue={state?.selected?.bank_AccountNumber}
          sx={{ minWidth: 250 }} />
      </Box>
      <Box sx={boxStyle}>
        <TextField
          disabled
          label={"Swift Code"}
          defaultValue={state?.selected?.bank_SwiftCode}
          sx={{ minWidth: 250 }} 
          />
        <TextField
          disabled
          label={"IBAN or BIC"}
          defaultValue={state?.selected?.bank_IBAN_IBC_Code}
          sx={{ minWidth: 250 }} />
      </Box>
    </>
  );
}

export default BankDetails;