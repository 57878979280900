import {
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import numeral from 'numeral';
import Iconify from '@src/components/iconify/Iconify';
import { getAmountBalanceByCoinType } from '@src/utils/filter-helper';

const TableRowItem = (props: any) => {
  const result = getAmountBalanceByCoinType(props.userBalanceList || []);

  return (
    <TableRow hover role="checkbox">
      <TableCell align="inherit">{props.id}</TableCell>
      <TableCell align="inherit"> {(`${props.firstName || ""} ${props.lastName || ""}`).trim()}</TableCell>
      <TableCell align="inherit">{props.emailAddress}</TableCell>
      <TableCell align="inherit">{props.displayName}</TableCell>
      <TableCell align="inherit">{numeral(result.SILVER).format("0,000")}</TableCell>
      <TableCell align="inherit">{numeral(result.GOLD).format("0,000.00")}</TableCell>
      <TableCell align="inherit">{numeral(result["GOLD BONUS"]).format("0,000.00")}</TableCell>
      <TableCell align="left">{moment(props.enrollmentDate).format("MM-DD-YYYY hh:mm A")}</TableCell>
      <TableCell align="left">{props.isDeleted ? "Deactivated" : "Active"}</TableCell>
      <TableCell align="right">
        <IconButton size="large" color="inherit" onClick={props.handleOpenMenu}>
          <Iconify icon={'eva:more-vertical-fill'} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default TableRowItem;
