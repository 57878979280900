import React from 'react';
import { selectedPurchaseItems } from '@src/store/slices/purchase.slice';
import { StyledRoot, StyledWrapRight } from '../styles.css';
import ButtonExport from '@src/components/buttons/button-export';
import PurchaseFilterSidebar from './PurchaseFilterSidebar';
import { downloadExcel } from '@src/utils/download-helper';
import { paymentOption } from '@src/utils/filter-helper';
import { useAppSelector } from '@src/store/hooks/hooks';
import moment from 'moment';
import _ from 'lodash';
import { IconButton } from '@mui/material';
import Iconify from '@src/components/iconify';
import { usePurchaseService } from '@src/store/hooks';

export default function PurchaseListToolbar({ placeholder, filterName, onFilterName }: any) {
  const purchases = useAppSelector(selectedPurchaseItems);
  const [openFilter, setOpenFilter] = React.useState(false);
  const { onFetchPurchase } = usePurchaseService();

  const handleOpenFilter = () => setOpenFilter(true);
  const handleCloseFilter = () => setOpenFilter(false);
  const onExport = () => {
    const newJson = _.reduce(purchases, (result: any, value: any, key) => {
      result.push({
        id: value.id,
        'Email': value.emailAddress,
        'Purchase Item': value.purchaseItem,
        'Amount': value.amount,
        'Payment Option': paymentOption(value.paymentTypeID),
        'Status': value.status,
        'Transaction Date': moment(value.transactionDate).format("MM-DD-YYYY hh:mm A"),
      })

      return result;
    }, []);
    downloadExcel(newJson, 'export-purchase');
  };

  const handleRefresh = () => onFetchPurchase();

  return (
    <StyledRoot style={{ width: '100%' }}>
      {/* <StyledSearch
        value={filterName}
        onChange={onFilterName}
        placeholder={placeholder || "Search email address..."}
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      /> */}
      <StyledWrapRight style={{ width: '100%' }}>
        <PurchaseFilterSidebar
          openFilter={openFilter}
          onOpenFilter={handleOpenFilter}
          onCloseFilter={handleCloseFilter}
        />
        <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
          <Iconify icon={'mdi:refresh'} width={30} />
        </IconButton>
        <ButtonExport sx={{ marginLeft: 5 }} onClick={onExport} />
      </StyledWrapRight>
    </StyledRoot>
  );
}
