import React, { createContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useInputHelper } from '@src/utils/inputs-helper';

const initialState = {
  page: 0,
  order: 'asc',
  orderBy: 'name',
  filterName: '',
  rowsPerPage: 10,
  fromdate: null,
  todate: null,
  status: '',
  userid: ''
}

interface ContextValue {
  state: any;
  onDispatch: any;
}

export const PurchaseContext = createContext<ContextValue>({} as ContextValue);

interface ProviderProps {
  children: React.ReactElement;
}

const PurchaseProvider = ({ children }: ProviderProps) => {
  const location = useLocation();
  const { state, onDispatch } = useInputHelper({...initialState, 
    userid: location?.state?.user_id ?? ''});

  const value = {
    state, onDispatch,
  };

  return (
    <PurchaseContext.Provider value={value}>
      {children}
    </PurchaseContext.Provider>
  );
};

export default PurchaseProvider;
