import { useState } from 'react';
// @mui
import { Menu, Button, MenuItem, Typography, Box } from '@mui/material';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: 1, label: 'Sapphire' },
  { value: 2, label: 'Emerald' },
  { value: 3, label: 'Ruby' },
  { value: 4, label: 'Diamond' },
];

export default function CoinType(props: any) {
  const [open, setOpen] = useState(null);
  const [selected, setSelected] = useState(SORT_BY_OPTIONS[0]);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onSelectionChange = (value: any) => {
    setOpen(null);
    setSelected(value);
    props.onChangeValue(value.value);
  };

  return (
    <>
      <Button
        color="inherit"
        sx={{display: 'flex', minWidth: 200, height: 55, justifyContent: 'space-between', 
        border: 0.7, borderColor: 'rgba(0,0,0,0.2)', color: 'text.secondary'}}
        onClick={handleOpen}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        <Box>
          Change To:
          <Typography component="span" variant="subtitle2" sx={{ color: 'text.primary', marginLeft: 1, }}>
            {selected.label}
          </Typography>
        </Box>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === selected.value}
            onClick={() => onSelectionChange(option)}
            sx={{ typography: 'body2' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
