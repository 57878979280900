import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/users';
import Games from './pages/games';
import Purchase from './pages/purchase';
import Transaction from './pages/transaction';
import GameHistory from './pages/game-history';
import CoinHistory from './pages/coin-history';
import Redeem from './pages/redeem-transaction';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import { useAppSelector } from './store/hooks/hooks';
import { selectAuthLoggedIn } from './store/slices/auth.slice';
import Programs from './pages/programs';

export default function Router() {
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);

  const routing = React.useMemo(() => {
    const AllAccessChildren = [
      { element: <Navigate to="/dashboard/app" />, index: true },
      { path: 'app', element: <DashboardAppPage /> },
      { path: 'programs', element: <Programs /> },
      { path: 'users', element: <UserPage /> },
      { path: 'games', element: <Games /> },
      { path: 'purchase', element: <Purchase /> },
      { path: 'mostplayed', element: <Transaction /> },
      { path: 'redeem', element: <Redeem /> },
    ];

    if(isLoggedIn){
      return [{
        path: '/dashboard',
        element: <DashboardLayout />,
        children: AllAccessChildren,
      },{
        path: '/dashboard/users/report',
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="/dashboard/users/report/game-history" />, index: true },
          { path: 'game-history', element: <GameHistory /> },
          { path: 'coin-history', element: <CoinHistory /> },
        ],
      },{
        element: <SimpleLayout />,
        children: [
          { element: <Navigate to="/dashboard/app" />, index: true },
          { path: '404', element: <Page404 /> },
          { path: '*', element: <Navigate to="/dashboard" /> },
        ],
      }];
    }else{
      return [{
        element: <LoginPage />,
        children: [
          { element: <Navigate to="/login" />, index: true },
          { path: 'login', element: <LoginPage /> },
          { path: '*', element: <Navigate to="/login" /> },
        ],
      }];
    }
  }, [isLoggedIn]);
  
  const routes = useRoutes(routing);

  return routes;
}
