import React, { useState } from 'react';
import { Menu, Button, MenuItem, Typography, Box } from '@mui/material';
import Iconify from '../../../components/iconify';

function Dropdown(props: any) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onSelectionChange = (value: any) => {
    setOpen(null);
    props.onChangeValue(value);
  };

  return (
    <>
      <Button
        color="inherit"
        sx={{display: 'flex', minWidth: 250, height: 55, justifyContent: 'space-between', 
          mt: 2, border: 0.7, borderColor: 'rgba(0,0,0,0.2)', color: 'text.secondary'}}
        onClick={handleOpen}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        <Box>
          {props.label}
          <Typography component="span" variant="subtitle2" sx={{ color: 'text.primary', marginLeft: 1, }}>
            {props.value?.label}
          </Typography>
        </Box>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {props.options.map((option: any) => (
          <MenuItem
            key={option.value}
            selected={option.value === props.value.value}
            onClick={() => onSelectionChange(option)}
            sx={{ typography: 'body2' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default React.memo(Dropdown)
