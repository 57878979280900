/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import UserProvider from './provider';
import UserDetailsDialog from './user-details';
import UserBalanceDialog from './update-balance';
import UserTierLevelDialog from './update-tierlevel';
import Body from './body';


export default function UserPage() {
  return (
    <UserProvider>
    <>
      <Helmet>
        <title> Users </title>
      </Helmet>
      <Body />
      <UserDetailsDialog />
      <UserBalanceDialog />
      <UserTierLevelDialog />
    </>
    </UserProvider>
  );
}
