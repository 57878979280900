import * as React from 'react';
import { Button, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

interface HeaderProps {
  id: string;
  loading: boolean;
  editable: boolean;
  onEdit: () => void;
  onSave: () => void;
  children?: React.ReactNode;
}

function Header(props: HeaderProps) {
  const { children, editable, onEdit, onSave } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} >
      {children}
      <Div>
        <Button onClick={onEdit} >EDIT</Button>
        {editable && <LoadingButton loading={props.loading} onClick={onSave}>SAVE</LoadingButton>}
      </Div>
    </DialogTitle>
  );
}

const Div = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  right: 10,
  top: 10,
});

export default React.memo(Header);
