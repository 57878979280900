// DUCKS pattern
import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import * as Types from '../types';
import _ from 'lodash';

export interface StateValue {
  loading: boolean;
  data: Types.UsersValue;
  error: Types.Error;
}

const initialState: StateValue = {
  loading: false,
  data: {} as Types.UsersValue,
  error: {} as Types.Error,
} as StateValue;

// Slice
export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    transactionRequest: (state, action) => {
      state.loading = true;
      if(action.payload.filter){
        state.data = {} as Types.UsersValue;
      }
    },
    transactionSuccess: (state, action) => {
      let items = action.payload.items;
      if(action.payload.isFetchMore){
        items = _.union(state.data?.items ?? [], action.payload.items);
      }

      state.data = { ...action.payload, items};
      state.loading = false;
    },
    transactionFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetError: () => {
      return initialState;
    },
  },
});

// Actions
export const transactionActions = {
  transactionRequest: createAction(
    `${transactionSlice.name}/transactionRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  transactionSuccess: transactionSlice.actions.transactionSuccess,
  transactionFailure: transactionSlice.actions.transactionFailure,
  resetError: transactionSlice.actions.resetError,
};

// Selectors
export const selectedTransactionLoading = (state: RootState) => state.transaction.loading;
export const selectedTransactionData = (state: RootState) => state.transaction.data;
export const selectedTransactionFailed = (state: RootState) => state.transaction.error;
export const selectedTransactionItems = createSelector(
  (state: RootState) => state.transaction.data,
  state => state.items || []
);
export const selectedTransactionTotal = createSelector(
  (state: RootState) => state.transaction.data,
  state => state.totalRecords || 0
);

// Reducer
export default transactionSlice.reducer;
