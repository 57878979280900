import moment from 'moment';
import * as XLSX from "xlsx";

export const downloadExcel = (arrays: any, filename: string) => {
  const worksheet = XLSX.utils.json_to_sheet(arrays);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${filename}-${moment().format("YYYY-MM-DD HH:mm:ss")}.xlsx`);
};

