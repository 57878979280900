import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  selectedAuthToken,
} from '../slices/auth.slice';
import { redeemActions } from '../slices/redeem.slice';

type ServiceOperators = {
  onFetchRedeem: () => void;
  onFetchRedeemMore: (params: any) => void;
  onUpdateRedeemState: (params: any) => void;
  onUpdateRedeemStatus: (params: any) => void;
};

export const useRedeemService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectedAuthToken);

  return {
    onFetchRedeem: useCallback(
      () => {
        dispatch(redeemActions.redeemRequest({ 
          token,
          "page": 1,
          "size": 50,
          "sort":"requestDate,desc" 
        }));
      },
      [dispatch, token]
    ),
    onFetchRedeemMore: useCallback(
      (params: any) => {
        dispatch(redeemActions.redeemRequest({
          token,
          ...params
        }));
      },
      [dispatch, token]
    ),
    onUpdateRedeemState: useCallback(
      (params: any) => {
        dispatch(redeemActions.updateRedeemState(params));
      },[dispatch]
    ),
    onUpdateRedeemStatus: useCallback(
      (params: any) => {
        dispatch(redeemActions.updateRedeemRequest({...params, token}));
      },[dispatch, token]
    ),
  };
};

export default useRedeemService;
