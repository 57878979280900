import {
  TableRow,
  TableCell,
  Typography,
  Stack,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import { gameCategory, getProvider } from '@src/utils/filter-helper';
import Iconify from '@src/components/iconify/Iconify';

const TableRowItem = (props: any) => {
  
  return (
    <TableRow hover tabIndex={-1} role="checkbox">
      <TableCell component="th" scope="row" padding="none">
        <Stack direction="row" alignItems="center" ml={2} spacing={2}>
          <Typography variant="subtitle2" noWrap>
            {props.gameName}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="left">{getProvider(props.gameTypeID)}</TableCell>
      <TableCell align="left">{props.sequence}</TableCell>
      <TableCell align="left">{gameCategory(props.gameCategoryID)}</TableCell>
      <TableCell align="left">{props.code}</TableCell>
      <TableCell align="left">{props.tierID}</TableCell>
      <TableCell align="left">{props.isActive === 1 ? "Active" : "Inactive"}</TableCell>
      <TableCell align="left">{props.isTop === 1 ? "Yes" : "No"}</TableCell>
      <TableCell align="left">{props.isGoldAllow === 1 ? "Yes" : "No"}</TableCell>
      <TableCell align="left">{moment(props.enrollmentDate).format("MM-DD-YYYY hh:mm A")}</TableCell>
      <TableCell align="left">{moment(props.lastModifiedDate).format("MM-DD-YYYY hh:mm A")}</TableCell>
      <TableCell align="right">
        <IconButton size="large" color="inherit" onClick={props.handleOpenMenu}>
          <Iconify icon={'eva:more-vertical-fill'} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default TableRowItem;
