import * as React from 'react';
import { RedeemContext } from '../provider';
import { Box, TextField, Typography } from '@mui/material';

const boxStyle = { display: 'flex', flex: 1, flexDirection: 'row', 
   justifyContent: 'space-around', marginTop: 2 };

const PromotionItem = () => {
  const { state } = React.useContext(RedeemContext);

  if(state?.selected?.redeemTypeID !== 4){
    return null;
  }

  return (
    <>
      <Typography sx={{ m: 0, p: 2, fontSize: 14, fontWeight: "600" }}>
        Promotion Item
      </Typography>
      <Box sx={boxStyle}>
        <TextField
          disabled
          label={"Product ID"}
          defaultValue={state?.selected?.productID}
          sx={{ minWidth: 250 }} 
          />
        <TextField
          disabled
          label={"Product Name"}
          defaultValue={state?.selected?.productName}
          sx={{ minWidth: 250 }} />
      </Box>
    </>
  );
}

export default PromotionItem;