/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { selectedAuthToken, selectedAuthUserId } from '@src/store/slices/auth.slice';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { UserContext } from '../provider';
import { TextField, CircularProgress } from '@mui/material';
import TierLevel from './tier-level';
import { LoadingButton } from '@mui/lab';
import { useAppSelector } from '@src/store/hooks/hooks';
import { useFetchGet } from '@src/utils/api/hooks';
import { tierLevelStatus } from '@src/utils/filter-helper';
import { updateTier } from '@src/utils/api';
import _ from 'lodash';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function UserTierLevelDialog(props: any) {
  const token = useAppSelector(selectedAuthToken);
  const { loading, data, runRequest } = useFetchGet();
  const [loading2, setLoading] = React.useState(false);
  const [coinTypeID, setCoinTypeID] = React.useState(1);
  const { state, onDispatch } = React.useContext(UserContext);
  const authorizedByUserID = useAppSelector(selectedAuthUserId);
  
  const handleClose = () => onDispatch("updatetierlevel")({});

  const handleUpdate = async() => {
    try{
      setLoading(true);
      const params = { 
        userID: state?.updatetierlevel?.id,
        "authorizedByUserID": authorizedByUserID,
        "tierID": coinTypeID,
      }

      await updateTier(params, token);
      setLoading(false);
      onDispatch("updatetierlevel")({});
    }catch(e){
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if(state?.updatetierlevel?.id){
      runRequest(`/api/v1/evo/avatartier/${state?.updatetierlevel?.id}`);
    }
  }, [state?.updatetierlevel?.id]);

  return (
    <div>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={!_.isEmpty(state.updatetierlevel)}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Update Tier Level
        </DialogTitle>
        <DialogContent dividers sx={{display: 'flex', flex: 1, 
          flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around'}}>
          {loading ? <CircularProgress /> : 
            <>
              <TextField
                disabled
                label={"Current Tier Level"}
                value={tierLevelStatus(data?.tierID)}
                sx={{
                  marginRight: 2,
                  minWidth: 200,
                }}
              />
              <TierLevel onChangeValue={setCoinTypeID} />
            </>
          }
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading || loading2} onClick={handleUpdate}>
            Update
          </LoadingButton>
          <Button disabled={loading || loading2} autoFocus sx={{color: 'text.secondary'}} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}