/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import HistoryProvider from './provider';
import Body from './body';


export default function UserPage() {
  return (
    <HistoryProvider>
    <>
      <Helmet>
        <title> Game history </title>
      </Helmet>
      <Body />
    </>
    </HistoryProvider>
  );
}
