import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  selectedAuthToken,
} from '../slices/auth.slice';


// Types
import * as Types from '../types';
import {selectedGameHistoryItems, 
  selectedGameHistoryData, 
  selectedGameHistoryFailed, 
  selectedGameHistoryLoading, 
  gamehistoryActions} from '../slices/gamehistory.slice';

type ServiceOperators = {
  data: Types.UsersValue;
  gamehistory: any;
  isLoading: boolean;
  failed: Types.Error;
  onFetchGameHistory: (user_id: number) => void;
  onFetchGameHistoryMore: (params: any) => void;
  onExportGameHistory: (params: any) => void;
};

export const useGameHistoryService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectedAuthToken);

  return {
    data: useAppSelector(selectedGameHistoryData),
    gamehistory: useAppSelector(selectedGameHistoryItems),
    failed: useAppSelector(selectedGameHistoryFailed),
    isLoading: useAppSelector(selectedGameHistoryLoading),
    onFetchGameHistory: useCallback(
      (user_id: number) => {
        dispatch(gamehistoryActions.gamehistoryRequest({ 
          token,
          user_id,
          refresh: true,
          "page": 1, 
          "size": 50, 
          "sort": "CreatedDate,desc"
        }));
      },
      [dispatch, token]
    ),
    onFetchGameHistoryMore: useCallback(
      (params: any) => {
        dispatch(gamehistoryActions.gamehistoryRequest({
          token,
          isFetchMore: true,
          ...params
        }));
      },
      [dispatch, token]
    ),
    onExportGameHistory: useCallback(
      (params: any) => {
        dispatch(gamehistoryActions.gamehistoryExportRequest({ 
          token,
          ...params
        }));
      },
      [dispatch, token]
    ),
  };
};

export default useGameHistoryService;
