import React from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { LocalizationProvider } from '@mui/x-date-pickers-pro'; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { STATUS_OPTIONS } from '@src/utils/mockdata/menu-options';
import { PurchaseContext } from '@src/pages/purchase/provider';
import { usePurchaseService } from '@src/store/hooks';
import DropDownMenu from '@src/components/menu';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import _ from 'lodash';

const PurchaseFilterSidebar = ({ openFilter, onOpenFilter, onCloseFilter }: any) => {
  const [error, setError] = React.useState<any>({});
  const { isLoading, onFetchPurchaseMore } = usePurchaseService();
  const { state, onDispatch } = React.useContext(PurchaseContext);

  const applyFilter = () => {
    const newError: any = {};
    setError(newError);

    if(_.isEmpty(newError)){
      onDispatch("page")(0);
      onFetchPurchaseMore({
        user_id: state.userid,
        status: state.status,
        from_date: state.fromdate ? moment(state.fromdate).format("YYYY-MM-DD") : null,
        to_date:  state.todate ? moment(state.todate).format("YYYY-MM-DD") : null,
        filter: true,
        "payment_type_id":"",
        "page": 1,
        "size": 50,
        "sort":"transactionDate,desc"
      });
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 2 }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Search by User ID
              </Typography>
              <TextField fullWidth
                value={state.userid}
                label="USER ID" 
                variant="outlined"
                onChange={(e: any) => onDispatch("userid")(e.target.value)} />
            </div>
          </Stack>
          <Stack spacing={2} sx={{ p: 2 }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Status
              </Typography>
              <DropDownMenu options={STATUS_OPTIONS} 
                value={state.status}
                onSort={(e: any) => onDispatch("status")(e.target.value)} />
            </div>
          </Stack>
          <Stack spacing={5} sx={{ p: 2 }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Transaction date
              </Typography>
              <MobileDatePicker
                label="From"
                value={state.fromdate}
                sx={{marginTop: 2}}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: 'outlined',
                    error: !_.isEmpty(error.fromdate),
                    helperText: error?.fromdate,
                  },
                }}
                onChange={onDispatch("fromdate")}
              />
              <MobileDatePicker
                label="To"
                sx={{marginTop: 3}}
                value={state.todate}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: 'outlined',
                    error: !_.isEmpty(error.todate),
                    helperText: error?.todate,
                  },
                }}
                onChange={onDispatch('todate')}
              />
            </div>
          </Stack>
        </Scrollbar>
        <Box sx={{ p: 3 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            loading={isLoading}
            onClick={applyFilter}
            startIcon={<Iconify icon="ic:check" />}
          >
            Apply
          </LoadingButton>
        </Box>
      </Drawer>
    </LocalizationProvider>
  );
}

export default PurchaseFilterSidebar;
