/* eslint-disable react-hooks/exhaustive-deps */
import GameDetailsDialog from './game-details';
import { Helmet } from 'react-helmet-async';
import GamesProvider from './provider';
import Body from './body';


export default function Games() {
  return (
    <GamesProvider>
    <>
      <Helmet>
        <title> Games </title>
      </Helmet>
      <Body />
      <GameDetailsDialog />
    </>
    </GamesProvider>
  );
}
