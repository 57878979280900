/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import CoinHistoryProvider from './provider';
import Body from './body';


export default function CoinHistory() {
  return (
    <CoinHistoryProvider>
    <>
      <Helmet>
        <title> Coin history </title>
      </Helmet>
      <Body />
    </>
    </CoinHistoryProvider>
  );
}
