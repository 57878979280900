/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Table,
  Stack,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';

// Helper
import { getComparator, applySortFilter } from '../../utils/filter-helper';
import { useTransactionService } from '@src/store/hooks';
// sections
import ScrollBar from '@src/components/scrollbar';
import { TransactionListToolbar } from '../../sections/@dashboard/transaction';
// mock
import { TABLE_HEAD_TRANSACTION } from '@src/utils/mockdata/table-headers';
import { selectedGamesData } from '@src/store/slices/games.slice';
import DataTableRow from '@src/components/data-table/data-row';
import TableHeader from '@src/components/data-table/header';
import { useAppSelector } from '@src/store/hooks/hooks';
import { GamesContext } from '../games/provider';
import { TransactionContext } from './provider';
import TableRowItem from './table-row';
import moment from 'moment';
import _ from 'lodash';

export default function Transaction() {
  const games = useAppSelector(selectedGamesData);
  const { state, onDispatch } = useContext(TransactionContext);
  const { onDispatch: onDispatchGames } = useContext(GamesContext);
  const { data, transactions, isLoading, onFetchTransactionsMore } = useTransactionService();

  const handleOpenMenu = (item: any) => (event: any) => {
    const findresult = _.find(games, { code: item.gameId} );
    onDispatchGames('selectedGame')(findresult);
    onDispatchGames('open')(event.currentTarget);
  };

  const handleRequestSort = (property: any) => {
    const isAsc = state.orderBy === property && state.order === 'asc';
    onDispatch('order')(isAsc ? 'desc' : 'asc');
    onDispatch('orderBy')(property);
  };

  const handleChangeRowsPerPage = (event: any) => {
    onDispatch('page')(0);
    onDispatch('rowsPerPage')(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: any) => {
    onDispatch('page')(0);
    onDispatch('filterName')(event.target.value);
  };

  const handleChangePage = (event: any, newPage: any) => {
    onDispatch('page')(newPage);
    if((newPage + 1) * state.rowsPerPage >  transactions.length ){
      onFetchTransactionsMore({
        fromDate: state.fromdate ? moment(state.fromdate).format("YYYY-MM-DD") : null,
        toDate: state.todate ? moment(state.todate).format("YYYY-MM-DD") : null,
        "page": newPage + 1,
        "size": state.rowsPerPage, 
        "sort": "CreatedDate,desc"
      });
    }
  };

  const filteredUsers = React.useMemo(() => 
    applySortFilter(transactions, getComparator(state.order, state.orderBy), state.filterName, "gameName"), 
    [transactions, state.filterName, state.order, state.orderBy]);
  const isNotFound = !filteredUsers.length && !!state.filterName;

  return (
    <>
      <Helmet>
        <title> Most Played </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Most Played
          </Typography>
        </Stack>
        <Card>
          <TransactionListToolbar placeholder="Search game name..." 
            filterName={state.filterName} onFilterName={handleFilterByName} />
          <ScrollBar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader
                  order={state.order}
                  orderBy={state.orderBy}
                  headLabel={TABLE_HEAD_TRANSACTION}
                  onRequestSort={handleRequestSort}
                />
                <DataTableRow
                  colSpan={9}
                  isLoading={isLoading}
                  isNotFound={isNotFound}
                  headers={TABLE_HEAD_TRANSACTION}
                  filterName={state.filterName}
                  data={filteredUsers.slice(state.page * state.rowsPerPage, 
                    state.page * state.rowsPerPage + state.rowsPerPage)}
                  renderItem={({item, index}: any) => {
                    return <TableRowItem key={`${index}`} {...item} handleOpenMenu={handleOpenMenu(item)} />
                  }} />
              </Table>
            </TableContainer>
          </ScrollBar>
          <TablePagination
            page={state.page}
            component="div"
            rowsPerPageOptions={[10, 20, 100]}
            count={data?.totalRecords ?? 0}
            rowsPerPage={state.rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
