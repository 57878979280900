import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './slices/auth.slice';
import usersSlice from './slices/users.slice';
import gamesSlice from './slices/games.slice';
import purchaseSlice from './slices/purchase.slice';
import gamehistorySlice from './slices/gamehistory.slice';
import coinhistorySlice from './slices/coinhistory.slice';
import transactionSlice from './slices/transaction.slice';
import redeemSlice from './slices/redeem.slice';

const reducer = combineReducers({
  auth: authSlice,
  users: usersSlice,
  games: gamesSlice,
  purchase: purchaseSlice,
  gamehistory: gamehistorySlice,
  coinhistory: coinhistorySlice,
  transaction: transactionSlice,
  redeem: redeemSlice,
});

export default reducer;
