/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Scrollbar from '@src/components/scrollbar';
// sections
import RedeemToolbar from '../../sections/@dashboard/redeem';
// mock
import { getComparator, applySortFilter } from '../../utils/filter-helper';
import { TABLE_HEAD_REDEEM_TRANSACTION } from '@src/utils/mockdata/table-headers';
import DataTableRow from '@src/components/data-table/data-row';
import TableHeader from '@src/components/data-table/header';
import { RedeemContext } from './provider';
import TableRowItem from './table-row';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedRedeemData, selectedRedeemItems, selectedRedeemLoading } from '@src/store/slices/redeem.slice';

const Body = () => {
  const data = useAppSelector(selectedRedeemData);
  const redeem = useAppSelector(selectedRedeemItems);
  const isLoading = useAppSelector(selectedRedeemLoading);
  const { tableContainerRef, state, onDispatch, } = useContext(RedeemContext);

  const handleOpenMenu = (item: any) => (event: any) => {
    onDispatch('selected')(item);
  };

  const handleRequestSort = (property: any) => {
    const isAsc = state.orderBy === property && state.order === 'asc';
    onDispatch('order')(isAsc ? 'desc' : 'asc');
    onDispatch('orderBy')(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    onDispatch('page')(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    onDispatch('page')(0);
    onDispatch('rowsPerPage')(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: any) => {
    onDispatch('page')(0);
    onDispatch('filterName')(event.target.value);
  };

  const filteredUsers = React.useMemo(() => 
    applySortFilter(redeem, getComparator(state.order, state.orderBy), 
      state.filterName, "userID"), 
  [redeem, state.filterName, state.order, state.orderBy]);

  const isNotFound = !filteredUsers.length && !!state.filterName;


  return (
    <>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Redeem Transactions
        </Typography>
      </Stack>
      <Card>
        <RedeemToolbar filterName={state.filterName} onFilterName={handleFilterByName} />
        <Scrollbar>
          <TableContainer ref={tableContainerRef} sx={{ overflowX: 'auto' }}>
            <Table>
              <TableHeader
                order={state.order}
                orderBy={state.orderBy}
                headLabel={TABLE_HEAD_REDEEM_TRANSACTION}
                onRequestSort={handleRequestSort}
              />
              <DataTableRow
                colSpan={9}
                isLoading={isLoading}
                isNotFound={isNotFound}
                filterName={state.filterName}
                headers={TABLE_HEAD_REDEEM_TRANSACTION}
                data={filteredUsers.slice(state.page * state.rowsPerPage, 
                  state.page * state.rowsPerPage + state.rowsPerPage)}
                renderItem={({item, index}: any) => {
                  return <TableRowItem key={`${index}`} {...item} handleOpenMenu={handleOpenMenu(item)} />
                }} />
            </Table>

          </TableContainer>
        </Scrollbar>
        <TablePagination
          page={state.page}
          component="div"
          rowsPerPage={state.rowsPerPage}
          count={data?.totalRecords ?? 0}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 20, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
    </>
  );
}

export default React.memo(Body);
