import * as React from 'react';
import { getAmountBalanceByCoinType, tierLevelStatus } from '@src/utils/filter-helper';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import TextView from './text-field';
import { Box } from '@mui/material';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {/* <CloseIcon /> */}
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function UserDetailsDialog(props: any) {
  const userdetails = React.useMemo(() => {
    const result = getAmountBalanceByCoinType(props.user?.userBalanceList ?? []);
    const userDetail = props.user?.userDetail ?? {};
    const userJade = props.user?.userJade ?? {};
    const status = props.user?.isDeleted ? "Deactivated" : "Active";

    return {
      ...props.user, 
      ...userDetail,
      ...result, 
      status, jadeAmout: userJade.amount
    }
  },[props.user]);

  console.log("userdetails", userdetails)

  return (
    <div>
      <BootstrapDialog
        fullWidth
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={props.visible}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
          User details
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{display: 'flex', flex: 1, 
          flexDirection: 'row', justifyContent: 'flex-start'}}>
          <Box>
            <TextView label="User ID" value={userdetails.id} />
            <TextView label="First Name" value={userdetails.firstName} />
            <TextView label="Last Name" value={userdetails.lastName} />
            <TextView label="Email Address" value={userdetails.emailAddress} />
            <TextView label="Date Of Birth" value={moment(userdetails.dateOfBirth).format("MM/DD/YYYY")} />
            <TextView label="Gender" value={userdetails.gender} />
            <TextView label="Country" value={userdetails.country} />
            <TextView label="Address" value={userdetails.street} />
            <TextView label="Phone Number" value={userdetails.phoneNumber} />
            <Box sx={{height: 15}} />
          </Box>
          <Box>
            <TextView label="Tier Level Status" value={tierLevelStatus(userdetails.tierId)} />
            <TextView label="Display Name" value={userdetails.displayName} />
            <TextView label="Status" value={userdetails.status} />
            <TextView label="Silver balance" value={numeral(userdetails.SILVER).format("0,000")} />
            <TextView label="GWz Gold" value={numeral(userdetails.GOLD).format("0,000.00")} />
            <TextView label="Gold balance" value={numeral(userdetails["GOLD BONUS"]).format("0,000.00")} />
            <TextView label="Jade Amount" value={numeral(userdetails.jadeAmout).format("0,000")} />
            <TextView label="Date Created" value={moment(userdetails.enrollmentDate).format("MM-DD-YYYY hh:mm A")} />
            <TextView label="Last Modified" value={moment(userdetails.lastModifiedDate).format("MM-DD-YYYY hh:mm A")} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.onClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}