// DUCKS pattern
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import * as Types from '../types';

export interface AuthState {
  loading: boolean;
  isLoggedIn: boolean;
  user: Types.UserDetails;
  error: Types.Error;
}

const initialState: AuthState = {
  loading: false,
  isLoggedIn: false,
  user: {} as Types.UserDetails,
  error: {} as Types.Error,
};

// Slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = {} as Types.Error;
    },
    loginSuccess: (state, action) => {
      state.user = action.payload;
      state.error = {} as Types.Error;
      state.isLoggedIn = true;
      state.loading = false;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    logout: () => {
      return initialState;
    },
  },
});

// Actions
export const authActions = {
  loginRequest: createAction(
    `${authSlice.name}/loginRequest`,
    (params: Types.LoginInput) => ({
      payload: params,
    })
  ),
  loginSuccess: authSlice.actions.loginSuccess,
  loginFailure: authSlice.actions.loginFailure,
  logout: authSlice.actions.logout,
};

// Selectors
export const selectAuthLoggingIn = (state: RootState) => state.auth.loading;
export const selectAuthLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectAuthLogInFailed = (state: RootState) => state.auth.error;
export const selectedAuthToken = (state: RootState) => state.auth.user?.token;
export const selectedAuthUserId = (state: RootState) => state.auth.user?.id;
export const selectUserFullname = createSelector(
  (state: RootState) => state.auth.user,
  (user) => `${user.firstName || ''} ${user.lastName || ''}`.trim()
);
export const selectUserEmail = createSelector(
  (state: RootState) => state.auth.user,
  (user) => user.emailAddress
);
export const selectUserTypeID = createSelector(
  (state: RootState) => state.auth.user,
  (user) => user.userTypeID
);

// Reducer
export default authSlice.reducer;
