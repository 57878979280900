import { StyledRoot, StyledSearch } from '../styles.css';
import ButtonExport from '@src/components/buttons/button-export';
import { selectedGamesData } from '@src/store/slices/games.slice';
import { downloadExcel } from '@src/utils/download-helper';
import { useAppSelector } from '@src/store/hooks/hooks';
import { getProvider } from '@src/utils/filter-helper';
import Iconify from '../../../components/iconify';
import { Box, IconButton, InputAdornment } from '@mui/material';
import GameSort from './GameSort';
import moment from 'moment';
import _ from 'lodash';
import { useGamesService } from '@src/store/hooks';

export default function GameListToolbar({ filterName, onFilterName }: any) {
  const games = useAppSelector(selectedGamesData);
  const { onFetchGames } = useGamesService();

  const onExport = () => {
    const newJson = _.reduce(games, (result: any, value: any, key) => {
      result.push({
        id: value.id,
        'Game Name': value.gameName,
        'Provider': getProvider(value.gameTypeID),
        'Code': value.code,
        'Tier ID': value.tierID,
        'Status': value.isActive === 1 ? "Active" : "Inactive",
        'New Game': value.isNew === 1 ? "Yes" : "No",
        'For Features': value.isFeatured === 1 ? "Yes" : "No",
        'Created Date': moment(value.enrollmentDate).format("MM-DD-YYYY hh:mm A"),
        'Last Update': moment(value.lastModifiedDate).format("MM-DD-YYYY hh:mm A"),
      })

      return result;
    }, []);
    downloadExcel(newJson, 'export-games');
  };

  const handleRefresh = () => onFetchGames();

  return (
    <StyledRoot sx={{ justifyContent: 'space-between' }}>
      <StyledSearch
        value={filterName}
        onChange={onFilterName}
        placeholder="Search game name..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
      <Box>
        <GameSort />
        <IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
          <Iconify icon={'mdi:refresh'} width={30} />
        </IconButton>
        <ButtonExport sx={{ marginLeft: 5 }} onClick={onExport} />
      </Box>
    </StyledRoot>
  );
}
