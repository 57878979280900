import React from 'react'
import { TextField } from '@mui/material'

function TextView(props: any) {
  return (
    <TextField
      label={props.label}
      defaultValue={props.value}
      sx={{
        marginTop: 2, 
        marginRight: 2,
        minWidth: 250,
      }}
      InputProps={{
        readOnly: true,
      }}
    />
  )
}

export default TextView;