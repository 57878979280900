/* eslint-disable no-throw-literal */
type PayloadProps = {
  method: string;
  headers: any;
  body?: any;
  mode?: string;
  cache?: string;
  crossDomain?: boolean;
  credentials?: string;
};

class Request {
  private host: string;
  private token?: string;

  constructor(host: string) {
    this.host = host;
  }

  setToken = (token: string) => {
    this.token = token;
  };

  get = (route: string) => this._request(route, 'GET', null, null);

  post = (route: string, body: any, headers?: any) => this._request(route, 'POST', body, headers);

  patch = (route: string, body: any) => this._request(route, 'PATCH', body, null);

  put = (route: string, body: any) => this._request(route, 'PUT', body, null);

  delete = (route: string) => this._request(route, 'DELETE', null, null);

  _request = async (route: string, method: string, body: any | undefined, headers?: any | undefined) => {
    const payload: PayloadProps = {
      method,
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if(this.token === "NO TOKEN"){
      delete payload.headers.Authorization;
    }

    if (method !== 'GET' && method !== 'HEAD') {
      payload.body = JSON.stringify(body);
    }

    // for upload
    if (method === 'POST' && headers?.['Content-Type'] === 'multipart/form-data') {
      delete payload.headers['Content-Type'];
      payload.headers['Accept'] = '*/*';

      const formData = new FormData();

      Object.keys(body).forEach((key) => {
        formData.append(key, body[key]);
      });

      payload.body = formData;
    }

    const url = `${this.host}${route}`;

    return await this._sendHttpRequest(url, payload);
  };

  _sendHttpRequest = async (url: string, payload: any) => {
    payload.url = url;
    console.log('REQUEST PAYLOAD', payload);

    const response = await fetch(url, payload);
    console.log('REQUEST RESPONSE', url, response.ok, response.status);

    if (response.ok === false && response.status === 401) {
      throw { code: 401, statusCode: 401, message: 'Unauthorized' };
    }

    const result: any = await response.json();

    if (response.ok === false) {
      throw result.error;
    }

    return result;
  };
}

export default Request;
