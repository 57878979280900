import React, { useState } from 'react';
// @mui
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: 1, label: 'Slot' },
  { value: 2, label: 'Table' },
  { value: 3, label: 'Instant' },
  { value: 4, label: 'Action' },
  { value: 5, label: 'Puzzles' },
  { value: 6, label: 'Arcade' },
];

export default function GameCategory(props: any) {
  const [open, setOpen] = useState(null);
  const label = React.useMemo(() => {
    const filter = SORT_BY_OPTIONS.find((item: any) => {
      return item.value === props.value;
    });

    return filter?.label ?? "";
  }, [props.value]);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onSelectionChange = (value: any) => {
    setOpen(null);
    props.onChangeValue(value.value);
  };

  return (
    <>
      <Button
        color="inherit"
        onClick={handleOpen}
        sx={{display: 'flex', marginTop: 2, minWidth: 250, height: 55, justifyContent: 'space-between', border: 0.7, borderColor: 'rgba(0,0,0,0.2)', color: 'text.secondary'}}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        <Box sx={{fontWeight: "350", color: 'text.secondary'}}>
          Game Category:
          <Typography component="span" variant="subtitle2" sx={{ color: 'text.primary', marginLeft: 1, }}>
            {label}
          </Typography>
        </Box>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === props.value}
            onClick={() => onSelectionChange(option)}
            sx={{ typography: 'body2' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
