import React from 'react';
import { MenuItem, Popover } from '@mui/material'
import { usePurchaseService } from '@src/store/hooks';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './provider';

function ActionMenu(props: any) {
  const navigate = useNavigate();
  const { onDispatch } = React.useContext(UserContext);

  const { onFetchPurchaseMore } = usePurchaseService();

  const onViewMoreDetails = () => {
    props.handleCloseMenu();
    onDispatch('userdetails')(props.selectedUser);
  }

  const onUpdateBalance = () => {
    props.handleCloseMenu();
    onDispatch('updatebalance')(props.selectedUser);
  }

  const onUpdateTierLevel = () => {
    props.handleCloseMenu();
    onDispatch('updatetierlevel')(props.selectedUser);
  }

  const onPurchase = () => {
    navigate("/dashboard/purchase", { state: { user_id: props.selectedUser.id, email: props.selectedUser.emailAddress } });
    onFetchPurchaseMore({
      user_id: props.selectedUser.id,
      filter: true,
      "payment_type_id": "",
      "page": 1,
      "size": 50,
      "sort": "transactionDate,desc"
    });
  }

  const onGameHistory = () => {
    const state = {
      user_id: props.selectedUser.id,
      email: props.selectedUser.emailAddress,
      displayName: props.selectedUser.displayName
    };

    navigate(`/dashboard/users/report/game-history?u=${state.user_id}`, { state });
  }

  const onCoinHistory = () => {
    const state = {
      user_id: props.selectedUser.id,
      email: props.selectedUser.emailAddress,
      displayName: props.selectedUser.displayName
    };

    navigate(`/dashboard/users/report/coin-history?u=${state.user_id}`, { state });
  }

  return (
    <Popover
      open={Boolean(props.open)}
      anchorEl={props.open}
      onClose={() => props.handleCloseMenu()}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={onViewMoreDetails}>
        More details
      </MenuItem>
      <MenuItem onClick={onPurchase}>
        Purchase history
      </MenuItem>
      <MenuItem onClick={onGameHistory}>
        Game history
      </MenuItem>
      <MenuItem onClick={onCoinHistory}>
        Coin history
      </MenuItem>
      <MenuItem onClick={onUpdateBalance}>
        Update Balance
      </MenuItem>
      <MenuItem onClick={onUpdateTierLevel}>
        Update Tier Level
      </MenuItem>
    </Popover>
  )
}

export default ActionMenu;