import React from 'react';
import { Grid, Container, Typography, Tabs, Tab, Box, Card } from '@mui/material';
import FilterSection from './filter-section';
import { Helmet } from 'react-helmet-async';
import Items from './items';
import DataSection from './data-section';
import ProgramProvider from './provider';

const Programs = () => {
 
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <ProgramProvider>
    <>
      <Helmet>
        <title> Programs </title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>Programs</Typography>
      <Card sx={{p: 1, minHeight: 500}}>
        <Box >
          <Tabs
            value={value}
            onChange={handleChange}
          >
            <Tab label="Rev Share" />
          </Tabs>
        </Box>
        <FilterSection />
        <DataSection />
      </Card>
      </Container>
    </>
    </ProgramProvider>
  );
}

export default Programs;
