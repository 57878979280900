import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Stack, Typography } from '@mui/material';
// components
import Logo from '../components/logo';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      
      <StyledRoot>
      
        <Container maxWidth="sm">
          <StyledContent>
          <Stack alignItems="center" mb={1}>
          <Box sx={{ px: 2.5, py: 2, display: 'inline-flex' }}>
            <Logo sx={{width: 80, height: 80}} />
          </Box>
            <Typography mb={3} textAlign="center" variant="h4" gutterBottom>
              Sign in
            </Typography>
            </Stack>
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
