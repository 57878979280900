import GameDetailsDialog from '../games/game-details';
import GamesProvider from '../games/provider';
import TransactionProvider from './provider';
import MainScreen from './main';

const TransactionScreen = () => {

  return (
    <TransactionProvider>
      <GamesProvider>
        <>
        <MainScreen />
        <GameDetailsDialog />
        </>
      </GamesProvider>
    </TransactionProvider>
  );
}

export default TransactionScreen;
