import React, { Dispatch, SetStateAction, createContext } from 'react';

interface ContextValue {
  createUserVisible: boolean;
  setCreateUserVisible: Dispatch<SetStateAction<boolean>>;
}

export const HeaderContext = createContext<ContextValue>({} as ContextValue);

interface ProviderProps {
  children: React.ReactElement;
}

const HeaderProvider = ({ children }: ProviderProps) => {
  const [createUserVisible, setCreateUserVisible] = React.useState<boolean>(false);

  const value = {
    createUserVisible,
    setCreateUserVisible
  };

  return (
    <HeaderContext.Provider value={value}>
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderProvider;
