import { useState, useCallback } from "react";
import { authActions, selectedAuthToken } from "@src/store/slices/auth.slice";
import { useAppDispatch, useAppSelector } from "@src/store/hooks/hooks";
import { CurrentApi } from "../request";

export const useFetchPost = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState(null);
  const [error, setError] = useState<any>(null);
  const token: any = useAppSelector(selectedAuthToken);
  const [loading, setLoading] = useState<boolean>(false);

  const runRequest = useCallback(
    async (routes: string, params: any) => {
      try {
        setData(null);
        setLoading(true);
        CurrentApi.setToken(token);
        const result = await CurrentApi.post(`${routes}`, params);

        setData(result);
        setLoading(false);
      } catch (err: any) {
        console.log("errerr", err)
        const message = err?.error?.message || err.error || "Something went wrong";
        setError({ message });
        setLoading(false);

        if (message.includes("Unauthorized")) {
          dispatch(authActions.logout());
        }
      }
    },
    [token, dispatch]
  );

  return {
    loading,
    data,
    error,
    runRequest,
  } as any;
};
