import * as React from 'react';
import { selectedUsersLoadingUpdate } from '@src/store/slices/users.slice';
import { selectedAuthUserId } from '@src/store/slices/auth.slice';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useAppSelector } from '@src/store/hooks/hooks';
import DialogTitle from '@mui/material/DialogTitle';
import { useUserService } from '@src/store/hooks';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { UserContext } from '../provider';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CoinType from './coin-type';
import _ from 'lodash';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function UserBalanceDialog(props: any) {
  const [amount, setAmount] = React.useState(0);
  const [coinTypeID, setCoinTypeID] = React.useState(1);
  const { state, onDispatch } = React.useContext(UserContext);
  const userID = useAppSelector(selectedAuthUserId);
  const { balanceData, onUpdateBalance } = useUserService();
  const isLoading = useAppSelector(selectedUsersLoadingUpdate);
  
  const handleClose = () => {
    onDispatch("updatebalance")({})
  };

  const handleUpdate = () => {
    onUpdateBalance({
      userID: state.updatebalance.id,
      authorizedByUserID: userID,
      amount,
      coinTypeID
    })
  }

  React.useEffect(() => {
    if(!_.isEmpty(balanceData)){
      handleClose();
      setAmount(0);
      setCoinTypeID(1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceData]);

  return (
    <div>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={!_.isEmpty(state.updatebalance)}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Update Balance
        </DialogTitle>
        <DialogContent dividers sx={{display: 'flex', flex: 1, 
          flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around'}}>
          <CoinType onChangeValue={setCoinTypeID} />
          <TextField
            label={"Amount"}
            defaultValue={amount}
            onChange={(e: any) => setAmount(e.target.value)}
            sx={{
              marginRight: 2,
              minWidth: 200,
            }}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isLoading} onClick={handleUpdate}>
            Update
          </LoadingButton>
          <Button disabled={isLoading} autoFocus sx={{color: 'text.secondary'}} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}