// DUCKS pattern
import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import * as Types from '../types';
import _, { set } from 'lodash';

const userState = {
  page: 0,
  order: 'asc',
  orderBy: 'name',
  filterName: '',
  fromdate: null,
  todate: null,
  rowsPerPage: 10,
  selectedUser: {},
  userdetails: {},
  updatebalance: {},
  open: '',
}

export interface StateValue {
  loading: boolean;
  refreshing: boolean;
  loadingSearch: boolean;
  loadingUpdate: boolean;
  data: Types.UsersValue;
  balanceUpdate: Types.UsersValue;
  error: Types.Error;
  userState: any;
}

const initialState: StateValue = {
  loading: false,
  refreshing: false,
  data: {} as Types.UsersValue,
  error: {} as Types.Error,
  userState
} as StateValue;

// Slice
export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    usersRequest: (state, action) => {
      state.loading = true;
      if (action.payload.filter) {
        state.data = {} as Types.UsersValue;
      }
    },
    usersSuccess: (state, action) => {
      let items = _.unionBy(state.data?.items ?? [], action.payload.items, "id");
      state.data = { ...action.payload, items };
      state.loading = false;
    },
    usersFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    // refresh requests
    usersRefreshRequest: (state, action) => {
      state.refreshing = true;
      if (action.payload.filter) {
        state.data = {} as Types.UsersValue;
      }
    },
    usersRefreshSuccess: (state, action) => {
      state.data = {
        ...state.data,
        items: [...action.payload.items, ...state.data.items],
      }
      state.refreshing = false;
    },
    usersRefreshFailure: (state, action) => {
      state.error = action.payload;
      state.refreshing = false;
    },

    searchUserRequest: (state, action) => {
      state.loadingSearch = true;
      if (action.payload.filter) {
        state.data = {} as Types.UsersValue;
      }
    },
    searchUserSuccess: (state, action) => {
      // let items = _.unionBy(action.payload.items, state.data?.items ?? [], "id");
      // state.data["items"] = items;
      // state.loadingSearch = false;
      state.data = {
        ...state.data,
        items: [...(state.data?.items || []), ...action.payload.items]
      };
      state.loadingSearch = false;
    },
    searchUserFailure: (state, action) => {
      state.error = action.payload;
      state.loadingSearch = false;
    },

    updateBalanceRequest: (state) => {
      state.loadingUpdate = true;
    },
    updateBalanceSuccess: (state, action) => {
      const newItems = state.data?.items.map((item: any) => {
        if (item.id === action.payload.userID) {
          const userBalanceList = _.unionBy(action.payload.userBalanceList, item.userBalanceList, "id");

          return { ...item, userBalanceList };
        }
        return item;
      });

      state.balanceUpdate = action.payload;
      state.data["items"] = newItems;
      state.loadingUpdate = false;
    },
    updateBalanceFailure: (state, action) => {
      state.error = action.payload;
      state.loadingUpdate = false;
    },

    updateUserState: (state, action) => {
      state.userState[action.payload.key] = action.payload.value;
    },

    userReset: () => {
      return initialState;
    },
  },
});

// Actions
export const usersActions = {
  usersRequest: createAction(
    `${usersSlice.name}/usersRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  usersSuccess: usersSlice.actions.usersSuccess,
  usersFailure: usersSlice.actions.usersFailure,
  
  // refresh actions
  usersRefreshRequest: createAction(
    `${usersSlice.name}/usersRefreshRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  usersRefreshSuccess: usersSlice.actions.usersRefreshSuccess,
  usersRefreshFailure: usersSlice.actions.usersRefreshFailure,

  searchUserRequest: createAction(
    `${usersSlice.name}/searchUserRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  searchUserSuccess: usersSlice.actions.searchUserSuccess,
  searchUserFailure: usersSlice.actions.searchUserFailure,

  updateBalanceRequest: createAction(
    `${usersSlice.name}/updateBalanceRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  updateBalanceSuccess: usersSlice.actions.updateBalanceSuccess,
  updateBalanceFailure: usersSlice.actions.updateBalanceFailure,
  updateUserState: usersSlice.actions.updateUserState,
  userReset: usersSlice.actions.userReset,
};

// Selectors
export const selectedUsersLoading = (state: RootState) => state.users.loading;
export const selectedUsersRefreshing = (state: RootState) => state.users.refreshing;
export const selectedUsersLoadingSearch = (state: RootState) => state.users.loadingSearch;
export const selectedUsersLoadingUpdate = (state: RootState) => state.users.loadingUpdate;
export const selectedUsersData = (state: RootState) => state.users.data;
export const selectedBalanceUpdate = (state: RootState) => state.users.balanceUpdate;
export const selectedUsersFailed = (state: RootState) => state.users.error;
export const selectedUserState = (state: RootState) => state.users.userState;
export const selectedUserItems = createSelector(
  (state: RootState) => state.users.data,
  state => state.items || []
);
export const selectedUsersTotal = createSelector(
  (state: RootState) => state.users.data,
  state => state.totalRecords || 0
);

// Reducer
export default usersSlice.reducer;
